// 判断是否是Json
function judgeString(val) {
    if (typeof val == 'string') {
      try {
        const obj= JSON.parse(val);
          if(typeof obj == 'object' && obj ){
           
            return true;
        }else{
         
            return false;
        }
        
      } catch(e) {
          return false;
      }
    }
     }
     export default judgeString