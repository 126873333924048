// 异步js
window._AMapSecurityConfig = {

    securityJsCode:'f90deb323a6dfee03f7fde005eabc039',
  };
export const asyncJS = (src) => {
    var jsapi = document.createElement('script');
    jsapi.charset = 'utf-8';
    jsapi.src = src;
 
    document.head.appendChild(jsapi);
  };
  