<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
      <el-breadcrumb-item>店铺列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="overflow: auto;">
      <el-row>
        <el-col :span="24" >
        <div class="grid-content bg-purple-dark">
            <el-button class="el-buttonr" size="mini" type="primary" @click="outerVisible = true">入驻</el-button>
   
          </div></el-col>
      </el-row>
      <!-- 分类搜索 -->
      <el-row style="margin-top: 15px">
        <!--日期搜索-->
       
        <el-col class="mr15" :span="3.3">
          <el-input @input="onipukeyword" v-model="queryInfo.businessName" size="mini" placeholder="店铺名称" style="width:160px"  >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
         <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="手机号"  @input="oniputmobile" v-model="queryInfo.mobile" style="width:160px"  >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <!-- 城市搜索 -->
          <el-col class="mr15" :span="3.3" >
         <v-distpicker
               @province="onprovince"
                @city="oncity"
                class="v-distpicker"
                :province="queryInfo.province"
                :city="queryInfo.city"
                hide-area
              ></v-distpicker>
              </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.categoryId"
            clearable
            placeholder="商品一级分类"
            @change="getSelectGoodsListStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.secondaryCategoryId"
            clearable
            placeholder="商品二级分类"
            @change="getsecondaryCategoryId"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in secondaryCategoryIdDate"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            v-model="queryInfo.businessStatus"
            clearable
            placeholder="店铺状态"
            style="width:160px"
            @change="getSelectGoodsStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in businessStatusdate"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!--店铺数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        height="800"
        ref="singleTable"
        highlight-current-row
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        border
        row-key="id"
        stripe
      >
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="mobile" label="头像" width="150"  show-overflow-tooltip>
          <template  slot-scope="scope">
            <img style="margin: 0 0 0 20px" :src="scope.row.businessLogo" @click="ondialogimgsrc(scope.row.businessLogo)" alt="" width="50px" height="50px"  />
          </template>
        </el-table-column>
        <el-table-column  prop="businessPhone" label="手机号" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column  prop="userId" label="会员ID" width="200" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="businessName" label="店铺名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="店铺logo" width="150"  show-overflow-tooltip>
          <template  slot-scope="scope">
            <img style="margin: 0 0 0 20px" :src="scope.row.businessLogo" @click="ondialogimgsrc(scope.row.businessLogo)" alt="" width="50px" height="50px"/>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="门头照" width="150">
          <template slot-scope="scope" >
            <img style="margin: 0 0 0 20px" @click="ondialogimgsrc(scope.row.doorHeadPhoto)" :src="scope.row.doorHeadPhoto"  alt="" width="50px" height="50px"/>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="入驻城市" width="200" show-overflow-tooltip ></el-table-column>
        <el-table-column prop="district" label="入驻区县" width="200" show-overflow-tooltip ></el-table-column>
  
        <el-table-column prop="logisticsType" label="店铺物流类型" width="200" show-overflow-tooltip>
          <template slot-scope="scope" >
          <span v-if="scope.row.logisticsType==1">配送</span>
          <span v-else-if="scope.row.logisticsType==2">快递</span>
          <span v-else-if="scope.row.logisticsType==3">自提</span>
          <span v-else-if="scope.row.logisticsType==4">到店消费</span>
          <span v-else-if="scope.row.logisticsType==5">上门服务</span>
          <span v-else-if="scope.row.logisticsType==6">配送或自提</span>
          <span v-else-if="scope.row.logisticsType==7"> 到店或上门</span>
          </template>
        </el-table-column>
        
        <el-table-column prop="businessAddressJson" label="店铺地址" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column  label="营时间" width="250" show-overflow-tooltip>
        <template slot-scope="scope" >
        {{scope.row.businessStartTime}}—{{scope.row.businessEndTime}}
        </template>
        </el-table-column>
       
        <el-table-column prop="businessStatus" label="店铺状态" width="200" show-overflow-tooltip >
        <template slot-scope="scope" >
          <span v-if="scope.row.businessStatus==0">下架</span>
          <span v-else-if="scope.row.businessStatus==1">营业中</span>
          <!-- <span v-else-if="scope.row.businessStatus==2">删除</span> -->
          <span v-else-if="scope.row.businessStatus==3">关店</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="250" fixed="right" >
        <template  slot-scope="scope">
          <el-button size="mini" @click="getformula(scope.row.id,scope.row.city)" type="text" >编辑</el-button>
          <el-button @click="onset(scope.row.id) " size="mini" type="text">设置</el-button>
          <el-button size="mini"  type="text" @click="onclassifyVisible(scope.row.id) " >分类</el-button>
            <!-- <el-button size="mini" @click="addmerchandiseVisible(scope.row.id,scope.row.distributionType,scope.row.logisticsType)" type="text" >上架</el-button> -->
           <el-button size="mini" @click="checkmerchandiseVisible(scope.row.id)" type="text" >商品</el-button>
           <el-button size="mini"  type="text" @click="getContractDetailns(scope.row.userId) " >合同</el-button>
          <el-button v-if="scope.row.businessStatus==0" @click="enableBusi(scope.row.id,scope.row.userId)"  size="mini" type="text">启用</el-button>
            <el-button  v-else-if="scope.row.businessStatus==1" @click="enableBusir(scope.row.id)"   size="mini"  type="text">停用</el-button>
          <!-- <el-button size="mini" type="primary">审核</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, ]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 分类设置弹框 -->
    <el-dialog
      width="50%"
      title="店铺商品分类"
      :append-to-body="true"
      :visible.sync="classifyVisible"
    >
      <el-row>
          <el-button size="mini" @click="innerVisibleroput()" style="width: 70px" type="primary">新增</el-button>
      </el-row>
      <!--店铺分类数据展示区域-->
      <el-table
        v-loading="loading"
        :data="gridData"
        style="width: 70%"
        height="550px"
        ref="singleTable"
        highlight-current-row
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        row-key="id"
        stripe
      >
        <el-table-column label="排序" prop="orders" width="50"></el-table-column>
        <el-table-column prop="categoryName" label="分类名称" width="250" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" min-width="200" fixed="right">
        <template  slot-scope="scope">
          <el-button @click="redactVisibler(scope.row.id,scope.row.businessId,scope.row.categoryName,scope.row.orders)" size="mini" type="text">编辑</el-button>
          <el-button @click="remordactVisibler(scope.row.id,scope.row.businessId)" size="mini" type="text" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        width="30%"
        :append-to-body="true"
        :visible.sync="innerVisibler">
        <el-form  label-width="80px">
          <el-form-item label="分类名称" required>
              <el-input size="mini"  v-model="insertBusinessDate.categoryName" ></el-input>
          </el-form-item>
          <el-form-item label="分类排序" required>
           <el-input size="mini"  v-model="insertBusinessDate.orders" ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="innerVisibler=false">取 消</el-button>
          <el-button type="primary" @click="addinsertBusiness">确 定</el-button>
        </span>
      </el-dialog>
      <span slot="footer">
        
        <el-button type="primary" @click="classifyVisible=flase">退出</el-button>
      </span>
    </el-dialog>
    <!-- 店铺设置 -->
    <el-dialog
      width="50%"
      title="店铺设置"
      :append-to-body="true"
      :visible.sync="outerVisibler"
      :close-on-click-modal="false"
      v-if="outerVisibler"
    >
      <el-form :model="formsetdate">
            <el-form-item label="营业时间" required>
              <el-time-picker
                size="mini"
                is-range
                v-model="business"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                 @change="shopTime"
              >
              </el-time-picker>
            </el-form-item>
      
          <el-form-item label="店铺人均价格" required>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini"  v-model="formsetdate.shopAveragePrice"></el-input>
            </el-col>
          </el-form-item>
        <el-form-item label="最低消费" >
            <el-col class="mr15" :span="3.3">
              <el-input size="mini"  v-model="formsetdate.minConsumptionAmount"></el-input>
            </el-col>
          </el-form-item>
            <el-form-item label="推荐店铺图" required style="width: 430px">
              <el-upload
              action=""
              :http-request="upbusinessShowImgUrl"
              list-type="picture-card"
              :on-preview="handlebusinessShowImgUrl"
              :on-remove="handlebusinessShowImgRemove"
              :before-upload="beforeAvatarUpload"
              >
                <i class="el-icon-plus"></i>
           
              </el-upload>
              <el-dialog :visible.sync="businessShowImgUrlVisible" :append-to-body="true">
                <img width="100%" :src="businessImageUrl" alt="" />
              </el-dialog>
                <div style="margin: 0px 0px 0px 90px">
            <template>
              <img  @click="ondialogimgsrc(businessShowImgUrl)" :src="businessShowImgUrl" alt="" width="150px" style="margin: 15px 15px 0px 0px" >
            </template>
          </div>
            </el-form-item> 
         <!-- <el-form-item label="店铺橱窗图" required style="width: 430px">
              <el-upload
              :http-request="upWindowfigureUrl"
              list-type="picture-card"
              :on-preview="handlebindowfigureImg"
              :on-remove="handleindowfigureImgRemove"
              :before-upload="beforeAvatarUpload"
              >
               <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="indowfigureVisible" :append-to-body="true">
                <img width="100%" :src="WindowfigureUrl" alt="" />
              </el-dialog>
            </el-form-item> -->
            
             <div v-if="formsetdate.logisticsType===1">
          <el-form-item label="物流设置:" required>
               配送
            <div v-if="whether.whether1">
               <el-col :span="24" class="mr15" style="margin: 0px 0px 0px 50px">
              <el-radio v-model="formsetdate.distributionType" :label="1">商家配送</el-radio>
              <span>商家最大配送距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col> 
            <el-col style="margin: 0px 0px 0px 50px" :span="9" class="mr15"> <el-radio v-model="formsetdate.distributionType" :label="0">顺丰配送</el-radio><span>顺丰最大配送距离10公里</span></el-col>
          </div>
             </el-form-item>
      <el-form-item label="配送费是否按商品规格累加"  style="width: 430px">
      <el-switch v-model="formsetdate.deliveryfeeTypeStatus"></el-switch>
      </el-form-item>
 <el-form-item  label="打包费是否按商品规格累加"  style="width: 430px；">
      <el-switch  v-model="formsetdate.packageNumberAddStatus" >
</el-switch>
</el-form-item>
        </div>
      <el-row v-if="formsetdate.logisticsType===2">
        <el-form-item label="物流设置" required>
            <span>快递</span>
         </el-form-item>
         <el-form-item label="快递商名称" required style="width: 430px">
          <el-col class="mr15" :span="6">
          <el-input size="mini"  v-model="formsetdate.expressName" ></el-input>  
        </el-col>
  </el-form-item>
    <el-form-item label="快递费" required style="width: 430px">
          <el-col class="mr15" :span="6">
          <el-input size="mini"  v-model="formsetdate.expressFee" ></el-input>  
        </el-col>
  </el-form-item>

          </el-row>
            <el-row v-if="formsetdate.logisticsType===3">
        <el-form-item label="物流方式" required>
            <span>自提</span>
         </el-form-item>
 <el-form-item label="打包费是否按SKU的数量累加"  style="width: 430px">
      <el-switch
  v-model="formsetdate.packageNumberAddStatus"
 >
</el-switch>

</el-form-item>
         </el-row>
      
  <el-row v-if="formsetdate.logisticsType===4">
        <el-form-item label="物流方式" required>
            <span>到店消费</span>
         </el-form-item>
         </el-row>
            <el-row v-if="formsetdate.logisticsType===5">
    
                 <el-form-item label="物流设置" required>
                  上门服务
                      <!-- <el-col :span="24" style=" margin-left: 25px;" v-if="whether.whether2">
             <span>最大服务距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col>  -->
             </el-form-item>
          </el-row>
            <div v-if="formsetdate.logisticsType===6">
          <el-form-item label="物流设置:" required>
          配送与自提
            <div v-if="whether.whether1">
               <el-col :span="24" class="mr15" style="margin: 0px 0px 0px 50px">
              <el-radio v-model="formsetdate.distributionType" :label="1">商家配送</el-radio>
              <span>商家最大配送距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col> 
            <el-col style="margin: 0px 0px 0px 50px" :span="10" class="mr15"> <el-radio v-model="formsetdate.distributionType" :label="0">顺丰配送</el-radio><span>顺丰最大配送距离10公里</span></el-col>
          </div>
             </el-form-item>
               <el-form-item label="配送费是否按SKU的数量累加"  style="width: 430px">
      <el-switch
  v-model="formsetdate.deliveryfeeTypeStatus"
 >
</el-switch>

</el-form-item>
 <el-form-item label="打包费是否按SKU的数量累加"  style="width: 430px">
      <el-switch v-model="formsetdate.packageNumberAddStatus">
</el-switch>
</el-form-item>
        </div>
      <el-row v-if="formsetdate.logisticsType===7">
    
                 <el-form-item label="物流设置" required>
                到店与上门
                      <!-- <el-col :span="24" style=" margin-left: 25px;" v-if="whether.whether2">
             <span>最大服务距离:</span> <el-input style="width: 100px" size="mini" v-model="formsetdate.maxDistance" ></el-input> <span>公里</span></el-col>  -->
             </el-form-item>
          </el-row>
      </el-form>
        <span slot="footer">
          <el-button @click="outerVisibler = false">取 消</el-button>
          <el-button type="primary" :loading="sehe" @click="addformsetdate">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 店铺入驻区域 -->
    
    <el-dialog width="55%" :append-to-body="true"   title="店铺入驻" :visible.sync="outerVisible" v-if="outerVisible" :close-on-click-modal="false">
      
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px" > -->
        <!-- <el-button size="mini"   :type="setType==0?'primary':'goon'" :id="setType==0?'butto':''"  @click="setType = 0">店铺信息</el-button>
        <el-button size="mini"   :type="setType==1?'primary':'goon'" :id="setType==1?'butto':''" @click="setType = 1">资质信息</el-button> -->
      <!-- </el-row> -->
      <!-- 基础设置 -->
      <div v-if="setType === 0">
        <el-form :model="form">
          <el-form-item label="手机号" required>
            <el-col class="mr15" :span="3.3">
              <el-input @input="particulars" v-model="businessPhone" size="mini" placeholder="请输入会员手机号"   ></el-input>
            </el-col>
          </el-form-item>
        
            <el-form-item  label="入驻城市" required>
              <v-distpicker
                class="v-distpicker"
                @province="distpickerprovince"
                @city="distpickercity"
               @selected="addressChecked"
                :province="form.province"
                :city="form.city"
                :area="form.district"
              ></v-distpicker>
            </el-form-item>
            <el-form-item label="店铺名称" style="width: 270px" required>
              <el-col :span="3.3">
                <el-input v-model="form.businessName" size="mini"  placeholder="请输入店铺名称"></el-input>
              </el-col>
            </el-form-item>
          <el-form-item label="店铺电话" required>
              <el-input  style="width: 200px" v-model="form.businessPhone" size="mini" placeholder="请输入店铺电话"   ></el-input>
          </el-form-item>
              <el-form-item label="平台商品分类" required>
                <el-select
                  size="mini"
                    v-model="form.primaryCategoryId"
                  clearable
                  placeholder="一级分类"
                  @change="getSelectGoodspoe"
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in addommodityStatus"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-select
                  size="mini"
                 v-model="form.categoryId"
                  clearable
                  placeholder="二级分类"
                
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in addondaryCategoryIdDate"
                    :key="index"
                    :label="item.name"
                   :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              
       <el-form-item label="店铺物流类型:" required>
           <el-radio-group v-model="form.logisticsType">
       <el-radio :label="1">配送</el-radio>
       <el-radio :label="2">快递</el-radio>
       <el-radio :label="3">自提</el-radio>
       <el-radio :label="4">到店消费</el-radio>
        <el-radio :label="5">上门服务</el-radio>
        <el-radio :label="6">配送与自提</el-radio>
         <el-radio :label="7">到店与上门</el-radio>
        </el-radio-group>
     </el-form-item>
     
              <el-form-item label="门店地址" required>
                <el-button @click="innerVisibles()" size="mini" type="primary"
                  >添加门店地址</el-button
                > 
                 <el-table :data=" [form.businessAddressJson]" style="width: 100%">
              <el-table-column prop="province" label="省份" width="180"></el-table-column>
              <el-table-column prop="city" label="市" width="180"></el-table-column>
              <el-table-column prop="district" label="县 / 区" width="180"></el-table-column>
              <el-table-column prop="address" label="详细地址" width="300"></el-table-column>
            </el-table>
              </el-form-item>
           <div style="  display: flex;justify-content: space-between;">
           <el-col>
            <el-form-item label="门头照片" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopDoor"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewDoor"
              :on-remove="handlePictureCardRemoveDoor"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleDoor" :append-to-body="true">
              <img width="100%" :src="dialogImageDoorUrl" alt="" />
            </el-dialog>
          </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="门店Logo" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upLoadShopLogo"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewlogo"
             :on-remove="handlePictureCardRemoveLogo"
              :before-upload="beforeAvatarUpload"
            >
               <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblelog"  :append-to-body="true">
              <img width="100%" :src="dialogImagelogUrl" alt="" />
            </el-dialog>
          </el-form-item>
          </el-col>
          </div>
            <el-form-item label="店内图片" required>
               <el-upload
              action=""
              :limit="20"
              :http-request=" handlePictureCardPreviewStore"
              list-type="picture-card"
              :on-preview="handlePictureCardPreInPhotoList"
              :on-remove="handlePictureCardRemoveInPhotoList"
              :before-upload="beforeAvatarUpload"
            
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblePhoto"  :append-to-body="true">
              <img width="100%" :src="businessInPhotoUrl" alt="" />
            </el-dialog>
          </el-form-item>
        </el-form>
      </div>
      <!-- 资质信息 -->
     <el-divider> 资质信息</el-divider>
       <el-form>
         <div style="  display: flex;justify-content: space-between;">
         <el-col>
        <el-form-item label="身份证人像" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upIdentityCardPortrait"
              list-type="picture-card"
              :on-preview="handleIdentityCardPortrait"
               :on-remove="handleRemoveIdentityCardPortrait"
             
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-dialog :visible.sync="IdentityCardPortraitDoor" :append-to-body="true">
              <img width="100%" :src="IdentityCardPortraitUrl" alt="" />
            </el-dialog>
          </el-form-item>
      </el-col>
      <el-col>
           <el-form-item label="身份证国徽" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upReversesideofidcard"
              list-type="picture-card"
              :on-preview="handleReversesideofidcard"
           :on-remove="handleRemoveReversesideofidcard"
            
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="ReversesideofidcardDoor" :append-to-body="true">
              <img width="100%" :src="ReversesideofidcardUrl" alt="" />
            </el-dialog>
          </el-form-item>
          </el-col>
          </div>
            <div style="  display: flex;justify-content: space-between;">
            <el-col>
     <el-form-item label="营业执照" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upBusinessLicense"
              list-type="picture-card"
              :on-preview="handleBusinessLicense"
            :on-remove="handleRemoveBusinessLicense"  
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="BusinessLicenseDoors" :append-to-body="true">
              <img width="100%" :src=" BusinessLicenseUrls" alt="" />
            </el-dialog>
          </el-form-item>
          </el-col>
          <el-col>
         <el-form-item class="labelpin" label="经营许可证" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upBusinessCertificate"
              list-type="picture-card"
              :on-remove="handleRemoveBusinessCertificate"
              :on-preview="handleBusinessCertificate"
             
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="BusinessCertificateDoor" :append-to-body="true">
              <img width="100%" :src=" BusinessCertificateUrl" alt="" />
            </el-dialog>
          </el-form-item>
          </el-col>
           </div>
          </el-form>
         
      <span slot="footer" class="dialog-footer">
      <el-button @click="outerVisible=flase" >取 消</el-button>
        <el-button type="primary" :loading="sehe"  @click="addEnterlist">保存</el-button>
      </span>
            <!-- 地图选取经纬度弹窗 -->
      <el-dialog
        width="50%"
        :append-to-body="true"
        :visible.sync="innerVisible"
        :before-close="Maptoconfirm"
      >
        <el-form
          label-width="80px"
        >
          <template>
            <el-select
          class="select"
          v-model="this.keyWord"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="请输入地址"
          :remote-method="search"
          @change="searchc($event, 'change',)"
          
          style="width: 100%; margin-bottom: 10px"
        >
          <el-option
            v-for="(item, index) in searchOption"
            :key="item.name + index"
            :label="item.pname+item.cityname+item.adname+item.name+item.address"
            :value="item.pname+item.cityname+item.adname+item.name+item.address"
          >
          </el-option>
        </el-select>
    
       <div id="container" style="overflow:hidden;"></div>
            </template>
        </el-form>
        <span slot="footer">
          <el-button @click="Maptoconfirm" >取 消</el-button>
          <el-button type="primary"  @click="innerVisible= false">确 定</el-button
          >
        </span>
      </el-dialog>
    </el-dialog>


<!-- 店铺编辑 -->
      <el-dialog width="55%" :append-to-body="true"   title="店铺编辑" :visible.sync="outerVisiblepoer" :close-on-click-modal="false" v-if="outerVisiblepoer" >
      
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px" > -->
        <!-- <el-button size="mini"   :type="setType==0?'primary':'goon'" :id="setType==0?'butto':''"  @click="setType = 0">店铺信息</el-button>
        <el-button size="mini"   :type="setType==1?'primary':'goon'" :id="setType==1?'butto':''" @click="setType = 1">资质信息</el-button> -->
      <!-- </el-row> -->
      <!-- 基础设置 -->
      <div v-if="setType === 0">
        <el-form :model="formuladata">
        
            <el-form-item  label="入驻城市" required>
              <v-distpicker
               @provinc="addonprovince"
                class="v-distpicker"
               @selected="formulChecked"
                :province="formuladata.province"
                :city="formuladata.city"
                :area="formuladata.district"
              ></v-distpicker>
            </el-form-item>
            <el-form-item label="店铺名称" style="width: 270px" required>
              <el-col :span="3.3">
                <el-input v-model="formuladata.businessName" size="mini"  placeholder="请输入店铺名称"></el-input>
              </el-col>
            </el-form-item>
          <el-form-item label="店铺电话" required>
              <el-input  style="width: 200px" v-model="formuladata.businessPhone" size="mini" placeholder="请输入店铺电话"   ></el-input>
          </el-form-item>
              <el-form-item label="平台商品分类" required>
                <el-select
                  size="mini"
                 
                  v-model="formuladata.primaryCategoryId"
                  clearable
                  placeholder="一级分类"
                  @change="redactSelectGoods"
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in redactondaroicroin"
                    :key="index"
                   :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <el-select
                  size="mini"
                  v-model="formuladata.categoryId"
                  clearable
                  placeholder="二级分类"
                  class="mr15"
                  style="width: 150px"
                >
                  <el-option
                    v-for="(item, index) in  redactityStatus"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              
       <el-form-item label="店铺物流类型:" required>
           <el-radio-group v-model="formuladata.logisticsType">
       <el-radio :label="1">配送</el-radio>
       <el-radio :label="2">快递</el-radio>
       <el-radio :label="3">自提</el-radio>
       <el-radio :label="4">到店消费</el-radio>
        <el-radio :label="5">上门服务</el-radio>
        <el-radio :label="6">配送与自提</el-radio>
         <el-radio :label="7">到店与上门</el-radio>
        </el-radio-group>
     </el-form-item>
   
              <el-form-item  label="门店地址" required>
                <el-button @click="redactVisibles()" size="mini" type="primary"
                  >编辑门店地址</el-button
                >
                
                 <el-table :data="[formuladata.businessAddressJson]" style="width: 100%">
              <el-table-column prop="province" label="省份" width="180"></el-table-column>
              <el-table-column prop="city" label="市" width="180"></el-table-column>
              <el-table-column prop="district" label="县 / 区" width="180"></el-table-column>
              <el-table-column prop="address" label="详细地址" width="300"></el-table-column>
            </el-table>
              </el-form-item>
           <div style="  display: flex;justify-content: space-between;">
           <el-col>
            <el-form-item label="门头照片" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="revampLoadShopDoor"
              list-type="picture-card"
              :on-preview="revampPictureCardPreviewDoor"
              :on-remove="revampPictureCardRemoveDoor"
           
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="revamplogVisibleDoor" :append-to-body="true">
              <img width="100%" :src="revamplogImageDoorUrl" alt="" />
            </el-dialog>
             <div style="margin: 20px 0px 0px 80px">
            <template>
              <img @click="ondialogimgsrc(formudate.doorHeadPhoto)" :src="formudate.doorHeadPhoto" alt="" width="150px" style="margin: 0px 15px 0px 0" >
            </template>
          </div>
          </el-form-item>
              
          </el-col>

          <el-col>
            <el-form-item label="门店Logo" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="revampLoadShopLogo"
              list-type="picture-card"
              :on-preview="handlePictureCardPreviewlogo"
             :on-remove="handlePictureCardRemoveLogo"
              :before-upload="beforeAvatarUpload"
            >
               <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblelog"  :append-to-body="true">
              <img width="100%" :src="dialogImagelogUrl" alt="" />
            </el-dialog>
              <div style="margin: 20px 0px 0px 85px">
            <template>
              <img @click="ondialogimgsrc(formudate.businessLogo)" :src="formudate.businessLogo" alt="" width="150px" style="margin: 0px 15px 0px 0" >
            </template>
          </div>
          </el-form-item>
             
          </el-col>
          </div>
            <el-form-item label="店内图片" required>
               <el-upload
              action=""
              :limit="20"
              :http-request=" handlePictureCardPreviewStore"
              list-type="picture-card"
              :on-preview="handlePictureCardPreInPhotoList"
              :on-remove="handlePictureCardRemoveInPhotoList"
              :before-upload="beforeAvatarUpload"
            
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisiblePhoto"  :append-to-body="true">
              <img width="100%" :src="businessInPhotoUrl" alt="" />
            </el-dialog>
                 <div style="margin: 0px 0px 0px 77px; "  > 
            <template>
              <img   v-for="(i,n) in businessInPhotoJ" :key="n"  :src="i" alt="" @click="ondialogimgsrc(i)" width="150px" style="margin: 15px 15px 0px 0px" >
            </template>
          </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 资质信息 -->
     <el-divider> 资质信息</el-divider>
       <el-form>
         <div style="  display: flex;justify-content: space-between;">
         <el-col>
        <el-form-item label="身份证人像" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upIdentityCardPortraits"
              list-type="picture-card"
              :on-preview="handleIdentityCardPortraits"
               :on-remove="handleRemoveIdentityCardPortraits"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="IdentityCardPortraitDoors" :append-to-body="true">
              <img width="100%" :src="IdentityCardPortraitUrls" alt="" />
            </el-dialog>
          </el-form-item>
               <div style="margin: 0px 0px 0px 90px">
            <template>
              <img @click="ondialogimgsrc(formudate.businessCertificatesJson.legal_person_car_img_front)" :src="formudate.businessCertificatesJson.legal_person_car_img_front" alt="" width="150px" style="margin: 0px 15px 15pxpx 0" >
            </template>
          </div>
      </el-col>
      <el-col>
           <el-form-item label="身份证国徽" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upReversesideofidcards"
              list-type="picture-card"
              :on-preview="handleReversesideofidcards"
           :on-remove="handleRemoveReversesideofidcards"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
           
            <el-dialog :visible.sync=" ReversesideofidcardDoors" :append-to-body="true">
              <img width="100%" :src="ReversesideofidcardUrls" alt="" />
            </el-dialog>
          </el-form-item>
               <div style="margin: 0px 0px 0px 90px">
            <template>
              <img @click="ondialogimgsrc(formudate.businessCertificatesJson.legal_person_car_img_reverse)" :src="formudate.businessCertificatesJson.legal_person_car_img_reverse" alt="" width="150px" style="margin: 0px 15px 15px 0" >
            </template>
          </div>
          </el-col>
          </div>
            <div style="display: flex;justify-content: space-between;">
            <el-col>
     <el-form-item label="营业执照" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upBusinessLicenses"
              list-type="picture-card"
              :on-preview="handleBusinessLicenses"
            :on-remove="handleRemoveBusinessLicenses"
              
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="BusinessLicenseDoors" :append-to-body="true">
              <img width="100%" :src=" BusinessLicenseUrls" alt="" />
            </el-dialog>
          </el-form-item>
               <div style="margin: 0px 0px 0px 80px">
            <template>
              <img @click="ondialogimgsrc(formudate.businessCertificatesJson.licence_img)" :src="formudate.businessCertificatesJson.licence_img" alt="" width="150px" style="margin: 0px 15px 0px 0" >
            </template>
          </div>
          </el-col>
          <el-col>
         <el-form-item class="labelpin" label="经营许可证" required>
               <el-upload
              action=""
              :limit="1"
              :http-request="upBusinessCertificates"
              list-type="picture-card"
              :on-remove="handleRemoveBusinessCertificates"
              :on-preview="handleBusinessCertificates"
             
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="BusinessCertificateDoors" :append-to-body="true">
              <img width="100%" :src=" BusinessCertificateUrls" alt="" />
            </el-dialog>
             <div style="margin: 20px 0px 0px 90px">
            <template>
              <img @click="ondialogimgsrc(formudate.businessCertificatesJson.licence_img)"  :src="formudate.businessCertificatesJson.certificate_img" alt="" width="150px" style="margin: 0px 15px 0px 0" >
            </template>
          </div>
          </el-form-item>
          </el-col>
           </div>
          </el-form>
         
      <span slot="footer" class="dialog-footer">
      <el-button @click="outerVisiblepoer=flase" >取 消</el-button>
        <el-button :loading="sehe" type="primary" @click="redactBusinessInfo">修改</el-button>
      </span>
            <!-- 编辑地图选取经纬度弹窗 -->
      <el-dialog
        width="50%"
        :append-to-body="true"
        :visible.sync="redactinerVisible"
      :before-close="redactinerVisibler" 
      >
        <el-form
        
       
          label-width="80px"
        >
          <template>
            <el-select
          class="select"
          v-model="this.keyWord"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="请输入地址"
          :remote-method="searchcmpi"
          @change="searchcnpi($event, 'change',)"
          style="width: 100%; margin-bottom: 10px"
        >
          <el-option
            v-for="(item, index) in searchOption"
            :key="item.name + index"
            :label="item.pname+item.cityname+item.adname+item.name+item.address"
            :value="item.pname+item.cityname+item.adname+item.name+item.address"
          >
          </el-option>
        </el-select>
    
       <div id="container" style="overflow:hidden;"></div>
            </template>
        </el-form>
        <span slot="footer">
          <el-button @click="redactinerVisibler()" >取 消</el-button>
          <el-button type="primary"  @click="redactinerVisible= false">确 定</el-button
          >
        </span>
      </el-dialog>
    </el-dialog>

  <!-- 列表大图展示 -->
  
     <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
              <img width="100%" :src="dialogimgsrc" alt="" />
            </el-dialog>
             <!-- 上架商品区域 -->
    <el-dialog :append-to-body="true" :visible.sync="addmerchandise" fullscreen   title="上架商品">
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px">
        <el-button size="mini" type="goon"  :id="setType == 0 ? 'butto' : ''"  @click="setType = 0" >基础设置</el-button>
        <el-button  size="mini"  type="goon"  :id="setType == 1 ? 'butto' : ''"  @click="setType = 1" >价格设置</el-button>
        <el-button size="mini" type="goon"  :id="setType == 2 ? 'butto' : ''"  @click="setType = 2" >详情设置</el-button>
      </el-row> -->
      <!-- 基础设置 -->
      <!-- <div v-if="setType === 0"> -->
        <el-form :model="addmerchandiseDate" style="margin-left:20px ;">
          <!-- <el-form-item label="商品卖方" required >
            <el-col class="mr15" :span="3.3">
              <el-radio v-model="radio" label="1">卖家</el-radio>
              <el-radio v-model="radio" label="2">富航生活平台</el-radio>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini" placeholder="会员手机号"   ></el-input>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <span>123</span>
            </el-col>
          </el-form-item> -->
         <el-form-item label="商品名称" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="addmerchandiseDate.goodsName" ></el-input>
              </el-col>
            </el-form-item>
       <el-form-item label="商品列表图" required>
               <el-upload
               v-if="addmerchandise" 
              action=""
              :limit="1"
              :http-request="upGoodsRead"
              list-type="picture-card"
              :on-preview="revampGoodsRead"
           :on-remove="handleRemoveGoodsRead"
           :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="revampGoodsReadstust" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrl" alt="" />
            </el-dialog>
          </el-form-item>
         
            <el-form-item label="商品橱窗图"  required>
               <el-upload
               v-if="addmerchandise"
              action=""
              :limit="8"
              :http-request="upslideshow"
              list-type="picture-card"
              :on-preview="slideshowReversesideofidcard"
           :on-remove="slideshowPictureCardRemoveInPhotoList"
            :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="slideshowVisiblePhoto" :append-to-body="true">
              <img width="100%" :src="slideshowInPhotoUrl" alt="" />
            </el-dialog>
          </el-form-item>
     <el-form-item  class="mdr5"  label="商品橱窗视频" prop="Video">
     <el-upload  action=""
          
              :http-request="handleVideoSuccess"
              list-type="picture-card"
           :before-upload="beforeUploadVideo">
         <i class="el-icon-plus"></i>
    </el-upload>
    <video style="position: absolute;top: 0px; left: 250px;width: 200px;height: 150px" v-if="videoForm.Video !='' && videoFlag == false" :src="videoForm.Video" autoplay="autoplay" class="avatar" controls="controls">您的浏览器不支持视频播放</video>
<i v-if="videoForm.Video !='' && videoFlag == false" @click="onvideoForm" style="position: absolute;top: -5px; left: 445px;" class="el-icon-error"></i>
</el-form-item>
<el-form-item label="商品视频封面"  >
               <el-upload
               v-if="addmerchandise"
              action=""
              :limit="8"
              :http-request="upaddslidevideo"
              list-type="picture-card"
              :on-preview="addslideshowReversesideofidcardvideo"
           :on-remove="addslideshowPictureCardRemoveInPhotoListvideo"
            :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="addslideshowInPhotoUrlvideo" :append-to-body="true">
              <img width="100%" :src="addslideshowVisiblePhotovideo" alt="" />
            </el-dialog>
          </el-form-item>
            <el-form-item style="width: 300px" label="商品分类" required>
              <el-select
              style="width: 110px"
                size="mini"
                v-model="addmerchandiseDate.businessCategoryId"
                clearable
                placeholder="商品分类"
                class="mr15"
              >
                <el-option
                  v-for="(item, index) in searchBusinessCategoryList"
                  :key="index"
                  :label="item.categoryName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
       
          <el-row style="padding-top:15px ;">
         
              <el-form-item style="width: 430px" label="售卖周期" required v-if="addmerchandise">
                <el-date-picker
                 v-model="handleDate"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['10:00:00', '10:00:00']"
                  @change="goodsTime()"
                >
                </el-date-picker>
              </el-form-item>
           
             <el-form-item label="购买有效天数" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="addmerchandiseDate.effectDays" ></el-input>
              </el-col>
            </el-form-item>
          
             </el-row>
          
               <!-- <el-col   class="mr15" style="margin: 20px 0; display: flex; justify-content: left"  :span="24">
              <div style="margin: 0 20px 0 0">
                <el-button size="small" type="primary" @click="ongoodsDeta">添加商品描述</el-button>
              </div>
              <div>
                <div style=" display: flex; justify-content: left; margin: 0 0 10px 0;" v-for="(i,n) in goodsDetailsJson" :key="n">
                 <i style="margin: 7px 5px 0 -10px; color: red;" class="el-icon-error" @click=" remogoodsDetailsJson(n)" /> <el-input size="mini"  placeholder="请输入内容"  v-model="i.t1"  ></el-input>
                </div>
              </div>
            </el-col> -->
           <el-form-item label="默认规格:"   required>
          <div style="float: left;" class="mr15">  
               <span  class="mr15">商品价格</span>
                <el-input   v-model="addmerchandiseDate.goodsPrice" style="width: 100px" size="mini"   ></el-input>
          </div>
          <div style="float: left;" class="mr15">
          <span class="mr15">商品划线价</span>
                <el-input   v-model="addmerchandiseDate.scribePrice" style="width: 100px" size="mini"   ></el-input>
                </div>
             <div style="float: left;" class="mr15">
          <span class="mr15">库存量</span>
                <el-input   v-model="addmerchandiseDate.stockNum" style="width: 100px" size="mini"   ></el-input>
             </div>
              </el-form-item>
        <div style=" display: flex;width: 80%">  
           <el-form-item label="限购" style="width: 170px" >
                <el-input   v-model="addmerchandiseDate.limitNum" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
             <el-form-item label="下单可获得积分" style="width: 250px" >
                <el-input   v-model="addmerchandiseDate.rewardIntegral" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
               <el-form-item v-if="logisticsType!==4&&logisticsType!==5&&logisticsType!==7" label="商品重量" style="width: 270px" >
                <el-input   v-model="addmerchandiseDate.goodsWeight" style="width: 100px" size="mini"   ></el-input>
                  /g
            </el-form-item>
            
         <el-form-item v-if="addmerchandiseDate.goodsType==1|| addmerchandiseDate.goodsType==3||addmerchandiseDate.goodsType==6" label="打包费"  style="width: 250px" >
                <el-input v-model="addmerchandiseDate.packingFee" style="width: 100px" size="mini"   ></el-input>
                元/份
            </el-form-item>
             <el-form-item v-if="addmerchandiseDate.goodsType==1||addmerchandiseDate.goodsType==6"  label="商家配送费" style="width: 250px">
                <el-input   v-model="addmerchandiseDate.deliveryFee" style="width: 100px" size="mini"   ></el-input>
            元/公里
            </el-form-item>
            </div>
          
              <el-form-item   label="商品物流:" required>
        <span v-if="logisticsType==1">配送</span>
        <span v-else-if="logisticsType==2">快递</span>
        <span v-else-if="logisticsType==3">自提</span>
        <span v-else-if="logisticsType==4">到店消费</span>
        <span v-else-if="logisticsType==5">上门服务</span>
         <div v-if="logisticsType==6" >
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">配送</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">自提</el-checkbox>
              </div>
              <div v-else-if="logisticsType==7" >
              <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">到店消费</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">上门服务</el-checkbox>
         </div>
        <!-- <span v-else-if="addmerchandiseDate.goodsType==6"> 配送与自提</span>
        <span v-else-if="addmerchandiseDate.goodsType==7"> 到店与上门</span> -->
            </el-form-item>
                <el-form-item label="分享信息"  >
               <el-col :span="5">
          <el-input  v-model="addmerchandiseDate.shareJson" size="mini" ></el-input>
          </el-col>
           
        </el-form-item>
               <el-form-item label="使用规则"  required>
                <Editor v-if="addmerchandise" :value="child" style="margin-left:80px ;" v-model="childr" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'"/>
        </el-form-item>
             <el-form-item label="商品详情"  required>
            
         <Editor  v-if="addmerchandise" :value="content" style="margin-left:80px ;" v-model="contentrc" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'" />
         </el-form-item>
  </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="onremomerchandiseDate" >取消</el-button>
        <el-button :loading="sehe" @click="onaddmerchandiseDate" type="primary">上架</el-button>
      </span>
     <!-- 店铺内展示商品 -->
    </el-dialog>
        <el-dialog :close-on-click-modal="false" :append-to-body="true" width="60%" :visible.sync="checkmermerchandise"   title="商品列表">
              <el-row >
            <el-col :span="3.3" class="mr15" style="  float:right ;">
<el-button class="el-buttonr" size="mini"  type="text" @click="addmerchandiseVisible(businessList.businessId,businessdistributionType,businesslogisticsType)">添加商品</el-button>
          </el-col>
        <el-col class="mr15" :span="3.3">
        
          <el-input size="mini" v-model="businessList.keyword" @input="getGoodsListkow" placeholder="商品名称" >
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
          <el-col :span="3.3">
          <el-select
            size="mini"
            style=" width: 150px;"
            v-model="businessList.goodsStatus"
            clearable
            placeholder="商品状态"
            @change="getGoodsListkow"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in mergoodsStatusListList"
              :key="index"
             :label="item.label"
             :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

    <el-table
        :data="businessData"
        style="width: 100%"
        height="800"
        ref="singleTable"
        highlight-current-row
        border
          :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        row-key="id"
        stripe
      >
      <el-table-column prop="id" label="商品ID" width="100" show-overflow-tooltip/>
        <el-table-column prop="goodsName" label="商品名称" width="200" show-overflow-tooltip/>
        <el-table-column prop="previewUrl" width="100" label="商品列表图" >
          <template slot-scope="scope">
            <img
            @click="dialogimgs(scope.row.previewUrl)"
            
              :src="scope.row.previewUrl"
              alt=""
             width="80px" height="50px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="scribePrice" label="商品状态" width="100" show-overflow-tooltip>
    <template slot-scope="scope">
    <span v-if="scope.row.goodsStatus==0">上架中</span>
      <span v-else>已下架</span>
    </template>
       
        </el-table-column>
 <el-table-column prop="goodsPrice" label="成交价格" width="100" show-overflow-tooltip/>
         <el-table-column prop="stockNum" label="库存" width="100" show-overflow-tooltip/>
         <el-table-column prop="monthSales" label="总销量" width="100" show-overflow-tooltip/>
         <el-table-column prop="goodsEndingTime" label="下架时间" width="200" show-overflow-tooltip>
         <!-- <template slot-scope="scope">
   <span> {{time(scope.row.goodsEndingTime)}}</span>
    </template> -->
         </el-table-column>
         <el-table-column label="操作" min-width="180" fixed="right">
        <template slot-scope="scope">

          <el-button @click="searchGoodsByIds(scope.row.id,JSON.parse(scope.row.addressJson).latitude,JSON.parse(scope.row.addressJson).longitude,scope.row.businessId)" size="mini" type="text" >编辑</el-button>
        <el-button @click="specificationVisible(scope.row.id)" size="mini" type="text">规格</el-button>
        
             <el-button v-if="scope.row.goodsStatus==1" @click="updateGoodStatust(scope.row.id,0)" size="mini" type="text">上架</el-button>
              <el-button v-else @click="updateGoodStatust(scope.row.id,1)" size="mini" type="text">下架</el-button>
           <el-popconfirm @confirm="deleter(scope.row.id)" style="padding-left: 20px;" title="确定删除此内容吗？">
          <el-button  slot="reference" size="mini" type="text">删除</el-button>
          </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @current-change="businessListChange"
        :current-page="businessList.page"
        :page-sizes="[10, ]"
        :page-size="businessList.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="businesstotal"
      >
    
      </el-pagination>
    </el-dialog>
     <!-- 规格设置弹窗 -->
     <el-dialog   width="55%" :append-to-body="true" :visible.sync="specification"    title="商品规格">
      <el-button size="mini" type="primary" @click="addspecification = true">添加规格</el-button>
       <el-table
        :data="specificationDate"
        style="width: 100%"
        ref="singleTable"
        highlight-current-row
       
        row-key="id"
        stripe
      >
        <el-table-column prop="specificationName" label="规格名称" width="200" show-overflow-tooltip/>
        <el-table-column prop="dealPrice" label="成交价" width="150" show-overflow-tooltip/>
        <el-table-column prop="scribePrice" label="划线价" width="150" show-overflow-tooltip/>
        <el-table-column prop="stockNum" label="库存量" width="100" show-overflow-tooltip/>
        <el-table-column label="操作" min-width="200" fixed="right">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.specificationName=='默认'" @click="redactspecification(scope.row)" size="mini" type="text">编辑</el-button>
          <el-button  :disabled="scope.row.specificationName=='默认'" slot="reference" @click="delGoodsSpecifi(scope.row.id,scope.row.goodsId)" size="mini" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
     <!-- 添加规格 -->
         <el-dialog :append-to-body="true" :visible.sync="addspecification" width=" 30%" >
          <el-form  label-width="80px">
          <el-form-item label="规格名称" required>
              <el-input size="mini"  v-model="addspecificationDate.specificationName" ></el-input>
          </el-form-item>
          <el-form-item label="成交价" required>
              <el-input size="mini"  v-model="addspecificationDate.dealPrice" ></el-input>
          </el-form-item>
             <el-form-item label="划线价" required>
              <el-input size="mini"  v-model="addspecificationDate.scribePrice" ></el-input>
          </el-form-item>
             <el-form-item label="库存量" required>
              <el-input size="mini"  v-model="addspecificationDate.stockNum" ></el-input>
          </el-form-item>
        </el-form>
         <span slot="footer">
          <el-button @click="innerVisibler=false">取 消</el-button>
          <el-button type="primary" @click="addspecificationDater">确 定</el-button>
        </span>
         </el-dialog>
         <!-- 编辑规格 -->
           <el-dialog :append-to-body="true" :visible.sync="redactpecification" width=" 30%" >
          <el-form  label-width="80px">
          <el-form-item label="规格名称" required>
              <el-input size="mini"  v-model="redactspecificationDate.specificationName" ></el-input>
          </el-form-item>
          <el-form-item label="成交价" required>
              <el-input size="mini"  v-model="redactspecificationDate.dealPrice" ></el-input>
          </el-form-item>
             <el-form-item label="划线价" required>
              <el-input size="mini"  v-model="redactspecificationDate.scribePrice" ></el-input>
          </el-form-item>
             <el-form-item label="库存量" required>
              <el-input size="mini"  v-model="redactspecificationDate.stockNum" ></el-input>
          </el-form-item>
        </el-form>
         <span slot="footer">
          <el-button @click="redactpecification=false">取 消</el-button>
          <el-button type="primary" @click="redactspecificationDater">确 定</el-button>
        </span>
         </el-dialog>
     </el-dialog>
     <!-- 编辑商品区域 -->
    <el-dialog :append-to-body="true" :visible.sync="addmerchandiser" fullscreen :lock-scroll="false"     title="编辑商品">
      <!-- <el-row style="display: flex; justify-content: center; margin: -25px 0 20px">
        <el-button size="mini" type="goon"  :id="setType == 0 ? 'butto' : ''"  @click="setType = 0" >基础设置</el-button>
        <el-button  size="mini"  type="goon"  :id="setType == 1 ? 'butto' : ''"  @click="setType = 1" >价格设置</el-button>
        <el-button size="mini" type="goon"  :id="setType == 2 ? 'butto' : ''"  @click="setType = 2" >详情设置</el-button>
      </el-row> -->
      <!-- 基础设置 -->
      <!-- <div v-if="setType === 0"> -->
        <el-form :model="goodsdate" style="margin-left:20px ;">
          <!-- <el-form-item label="商品卖方" required >
            <el-col class="mr15" :span="3.3">
              <el-radio v-model="radio" label="1">卖家</el-radio>
              <el-radio v-model="radio" label="2">富航生活平台</el-radio>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <el-input size="mini" placeholder="会员手机号"   ></el-input>
            </el-col>
            <el-col class="mr15" :span="3.3">
              <span>123</span>
            </el-col>
          </el-form-item> -->
         <el-form-item label="商品名称" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="goodsdate.goodsName" ></el-input>
              </el-col>
            </el-form-item>
       <el-form-item label="商品列表图"  required>
               <el-upload
               v-if="addmerchandiser"
              action=""
              :limit="1"
              :http-request="upGoodsReadion"
              list-type="picture-card"
              :on-preview="revampGoodsReadion"
           :on-remove="handleRemoveGoodsReadion"
           :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="revampGoodsReadstust" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrl" alt="" />
            </el-dialog>
                 <template>
              <img   :src="previewUrl" alt="" @click="ondialogimgsrc(goodsdate.previewUrl)" width="150px" style="margin: 15px 15px 0px 90px" >
            </template>
          </el-form-item>
            <el-form-item label="商品橱窗图"  required>
               <el-upload
               v-if="addmerchandiser"
              action=""
              :limit="8"
              :http-request="upslideshowion"
              list-type="picture-card"
              :on-preview="slideshowReversesideofidcardion"
           :on-remove="slideshowPictureCardRemoveInPhotoListion"
            :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
           
            <el-dialog :visible.sync="slideshowVisiblePhotoion" :append-to-body="true">
              <img width="100%" :src="slideshowInPhotoUrlion" alt="" />
            </el-dialog>
            
             <template >
             <div style="margin: 0px 0px 0px 85px">
              <img   v-for="(i,n) in ImgsJson" :key="n"  :src="i" alt="" @click="ondialogimgsrc(i)" width="150px" height="100px" style="margin: 15px 15px 0px 10px" >
              </div>
            </template>
          </el-form-item>
      <el-form-item  class="mdr5" label="商品橱窗视频" prop="Video">
     
    <el-upload class="mdr5"  action=""
            
              :http-request="addhandleVideoSuccess"
              list-type="picture-card"
           :before-upload="beforeUploadVideo">
         <i class="el-icon-plus"></i>
         
    </el-upload>
    <video style="  position: absolute;top: 0px; left: 250px;width: 200px;height: 150px;" v-if="addvideoForm.Video" :src="addvideoForm.Video" autoplay="autoplay" class="avatar" controls="controls"></video>
     <i v-if="addvideoForm.Video" @click="onaddvideoForm" style="position: absolute;top: -5px; left: 445px;" class="el-icon-error"></i>
</el-form-item>
 <el-form-item label="商品视频封面" >
               <el-upload
               v-if="addmerchandiser"
              action=""
              :limit="1"
              :http-request="upGoodsReadvideo"
              list-type="picture-card"
              :on-preview="revampGoodsReadvideo"
           :on-remove="handleRemoveGoodsReadvideo"
           :before-upload="beforeAvatarUploadpoc"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <el-dialog :visible.sync="revampGoodsReadstustvideo" :append-to-body="true">
              <img width="100%" :src="revampGoodsReadUrlvideo" alt="" />
            </el-dialog>
                 <template>
                 
              <img   :src="addvideoPreviewImgUrl" alt="" @click="dialogimgs(addvideoPreviewImgUrl)" width="150px" style="margin: 15px 15px 0px 95px" >
              <i v-if="addvideoPreviewImgUrl"  @click="onvideoPreviewImgUrl()" style="position: absolute;top: 155px; left: 240px;" class="el-icon-error"></i>
            </template>
          </el-form-item>
            <el-form-item style="width: 300px" label="商品分类" required>
              <el-select
              style="width: 110px"
                size="mini"
                v-model="goodsdate.businessCategoryId"
                clearable
                placeholder="商品分类"
               
                class="mr15"
              >
                <el-option
                  v-for="(item, index) in searchBusinessCategoryList"
                  :key="index"
                  :label="item.categoryName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
       
          <el-row style="padding-top:15px ;">
         
              <el-form-item style="width: 430px" label="售卖周期" required v-if="addmerchandiser">
                <el-date-picker
                 v-model="handleDatet"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['10:00:00', '10:00:00']"
                  @change="goodsTimer()"
                >
                </el-date-picker>
              </el-form-item>
           
             <el-form-item label="购买有效天数" style="width: 100%" required>
              <el-col :span="3.3">
                <el-input size="mini"  v-model="goodsdate.effectDays" ></el-input>
              </el-col>
            </el-form-item>
          
             </el-row>
          <!-- <el-row >
               <el-col   class="mr15" style="margin: 20px 0; display: flex; justify-content: left"  :span="24">
              <div style="margin: 0 20px 0 0">
                <el-button size="small" type="primary" @click="ongoodsDetaion">添加商品描述</el-button>
              </div>
              <div>
                <div style=" display: flex; justify-content: left; margin: 0 0 10px 0;" v-for="(i,n) in goodsDetailsJsons" :key="n">
                 <i style="margin: 7px 5px 0 -10px; color: red;" class="el-icon-error" @click=" remogoodsDetailsJsonion(n)" /> <el-input size="mini"  placeholder="请输入内容"  v-model="i.t1"  ></el-input>
                </div>
              </div>
            </el-col>
               </el-row> -->
     <el-form-item label="默认规格:"   required>
          <div style="float: left;" class="mr15">  
          <span class="mr15">商品价格</span>   
                <el-input   v-model="goodsdate.goodsPrice" style="width: 100px" size="mini"   ></el-input>
          </div>
               <div style="float: left;" class="mr15">  
          <span class="mr15">商品划线价</span>  
                <el-input   v-model="goodsdate.scribePrice" style="width: 100px" size="mini"   ></el-input>
           </div>
              <div style="float: left;" class="mr15">  
          <span class="mr15">库存量</span> 
                <el-input   v-model="goodsdate.stockNum" style="width: 100px" size="mini"   ></el-input>
            </div>
              </el-form-item>
        <div style=" display: flex;width: 100%">
             <el-form-item label="限购"  class="mr15" style="width: 150px;float: left;" >
                <el-input   v-model="goodsdate.limitNum" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
             <el-form-item label="下单可获得积分" class="mr15" style="width: 250px;float: left;" >
                <el-input   v-model="goodsdate.rewardIntegral" style="width: 100px" size="mini"   ></el-input>
            </el-form-item>
               <el-form-item v-if="goodsdate.goodsType!==4&&goodsdate.goodsType!==4&&logisticsType!==7" label="商品重量" style="width: 200px" >
                <el-input   v-model="goodsdate.goodsWeight" style="width: 100px" size="mini"   ></el-input>
                  /g
            </el-form-item>     
         <el-form-item v-if="goodsdate.goodsType==1|| goodsdate.goodsType==3||logisticsType==6" label="打包费"  style="width: 200px" >
                <el-input v-model="goodsdate.packingFee" style="width: 100px" size="mini"   ></el-input>
                元/份
            </el-form-item>
           <el-form-item v-if="goodsdate.goodsType==1||logisticsType==6" label="商家配送费" style="width: 270px" required>
                <el-input   v-model="goodsdate.deliveryFee" style="width: 100px" size="mini"   ></el-input>
            元/公里
            </el-form-item>
            </div>
               <el-form-item   label="商品物流:" required>
              <div v-if="logisticsType==6" >
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">配送</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">自提</el-checkbox>
              </div>
              <div v-else-if="logisticsType==7" >
              <el-checkbox v-model="goodsTypewhether.goodsTypewhether1">到店消费</el-checkbox>
         <el-checkbox v-model="goodsTypewhether.goodsTypewhether2">上门服务</el-checkbox>
          </div>
        <span v-else-if="goodsdate.goodsType==1">配送</span>
        <span v-else-if="goodsdate.goodsType==2">快递</span>
        <span v-else-if="goodsdate.goodsType==3">自提</span>
        <span v-else-if="goodsdate.goodsType==4">到店消费</span>
        <span v-else-if="goodsdate.goodsType==5">上门服务</span>
            </el-form-item>
                <el-form-item label="分享信息"  >
               <el-col :span="5">
          <el-input  v-model="goodsdate.shareJson" size="mini" ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="使用规则"   required>
        <Editor v-if="addmerchandiser" :value="child" style="margin-left:80px ;" v-model="childr" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'"/>
        </el-form-item>
        <el-form-item label="商品详情"  required>
        <editor  v-if="addmerchandiser"  style="margin-left:80px ;" :value="content" v-model="contentc" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'"  />
        </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="addmerchandisertur()" >取消</el-button>
        <el-button :loading="onaddloding" @click="onaddmerchandiseDateion" type="primary">保存</el-button>
      </span>
    </el-dialog>
  <el-dialog :append-to-body="true" :visible.sync="getContractDetai"  :lock-scroll="false"     title="合同信息">
   <el-form :model="getContractDeta" style="margin-left:20px ;">
        <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">合同信息</span></el-divider>
        <div style=" overflow: hidden;"  v-if="!getContractDeta.contract">
        暂无合同信息
        </div >
        <div  v-else style=" overflow: hidden;">
         <p class="mr15" style=" float: left;margin-bottom:10px; ">合同编号：{{getContractDeta.contract.contractId}}</p>
        <p class="mr15" style=" float: left;margin-bottom:10px; ">发起方：{{getContractDeta.contract.sellerName}}</p>
        <p class="mr15" style=" float: left;margin-bottom:10px;">合同名称：{{getContractDeta.contract.contractTitle}}</p>
        
        <div class="mr15" style=" float: left;margin-bottom:10px;" ><span>合同状态：</span>
        <span v-if="getContractDeta.contract.contractStatus=='DRAFT'">草稿</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='RECALLED'">已撤回</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='SIGNING'">签署中</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='REJECTED'">已退回</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='COMPLETE'">已完成</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='EXPIRED'">已过期</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='FILLING'">拟定中</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='INVALIDING'">作废中</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='INVALIDED'">已作废</span>
        <span v-else-if="getContractDeta.contract.contractStatus=='FORCE_END'">强制结束</span>
        </div >
         <p class="mr15" style=" float: left;">合同签约时间：{{getContractDeta.contract.signTime}}</p>
        </div>
        <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">保证金信息</span></el-divider>
         <div   v-if="!getContractDeta.contractOrders">
        暂无保证金信息
        </div >
        <div style=" overflow: hidden;" v-else >
         <p class="mr15" style=" float: left;margin-bottom:10px; ">支付金额：{{getContractDeta.contractOrders.payMoney}}</p>
         
         <p class="mr15" style=" float: left;margin-bottom:10px; ">支付方式：{{getContractDeta.contractOrders.payType}}</p>
         <p class="mr15" style=" float: left;margin-bottom:10px; ">支付订单号：{{getContractDeta.contractOrders.orderNo}}</p>
         <div  class="mr15" style=" float: left;margin-bottom:10px; ">
         <span style="margin-right: 10px;">支付状态:</span>
         <span v-if=" getContractDeta.contractOrders.orderStatus==1">已完成</span>
           <span v-else>待支付</span>
        </div>
       <p class="mr15" style=" float: left;margin-bottom:10px; ">支付时间：{{getContractDeta.contractOrders.updateTime}}</p>
        </div>
          <el-divider content-position="left"><span style="font-size: 16px;font-weight: 600">加盟费信息</span></el-divider>
         <div   v-if="!getContractDeta.settledOrders">
        暂无加盟费信息
        </div >
        <div style=" overflow: hidden;" v-else >
         <p class="mr15" style=" float: left;margin-bottom:10px; ">加盟费金额：{{getContractDeta.settledOrders.payMoney}}</p>
         <p class="mr15" style=" float: left;margin-bottom:10px; ">加盟费支付方式：{{getContractDeta.settledOrders.payType}}</p>
         <p class="mr15" style=" float: left;margin-bottom:10px; ">加盟费支付订单号：{{getContractDeta.settledOrders.orderNo}}</p>
         <div  class="mr15" style=" float: left;margin-bottom:10px; ">
         <span style="margin-right: 10px;">加盟费状态:</span>
         <span v-if=" getContractDeta.settledOrders.orderStatus==1">已完成</span>
           <span v-else>待支付</span>
        </div>
       <p class="mr15" style=" float: left;margin-bottom:10px; ">加盟费支付时间：{{getContractDeta.settledOrders.updateTime}}</p>
        </div>
        </el-form>
        </el-dialog>
        
  </div>
</template>

<script>
// 引入拖动列表组件
import time from "@/utils/time";
import md5 from 'js-md5';
import getBrowserInfo from '../../utils/BrowserName'
import { uuid } from 'vue-uuid';
import Editor from "@/components/RichText";
import { asyncJS } from '../../utils/utils';
import dataFunction from "@/utils/dataFunction";
import judgeString from '../../utils/Json.js'
import {updateGoods,delGoods, delGoodsSpecification,updateGoodsSpecification,insertGoodsSpecification, 
searchGoodsSpecificationByGoodsId, searchGoodsList, getGoodCategoryListByCityName,
insertGoods, delBusinessCategory,insertBusinessCategory,searchBusinessCategoryListForPC,
enableBusiness,updateBusinessInfo,searchBusinessInfoById, searchBusinessInfoList,getUserByMobile,
insertBusinessInfo,getContractDetails,getCategoryList,getScondaryCategoryList,userparticulars,adminUpdate,
searchGoodsById} from "@/api";
export default {
  name: "UsesignList",
 components:{
  Editor
  },
  data() {
    
    return {
      
    // 店内商品
    businessData:[],
    checkmermerchandise:false,
      // 
      barginStatus:false,
      headers:{
equipmentIdentification:uuid.v1(),
clientId:window.localStorage.getItem('clientId'),
equipmentType:getBrowserInfo()[0],
equipmentName:getBrowserInfo()[0].split("/")[0],
requestTime: `${new Date().getTime()}`,
sign:md5(`${uuid.v1()}#${getBrowserInfo()[0].split("/")[0]}#${getBrowserInfo()[0]}#${md5(uuid.v1().slice(7,26))}#${ parseInt(new Date().getTime()/1000/180)}`),
testToken:window.sessionStorage.getItem('accessToken'),
      },
      contentrc:"",
      // 商品描述
      goodsDetailsJson:[],
      slideshowInPhotoUrl:"",
   slideshowVisiblePhoto:false,
      slideshowInPhotoindexList:[],
      slideshowInPhotoList:[],
       revampGoodsReadUrl:"",
           revampGoodsReadstust:false,
      handleDate:{},
      // 添加商品店内分类
       searchBusinessCategoryList:[],
      // 上架商品数据

      addmerchandiseDate:{
goodsDetailsJson:{}
      },
      logisticsType:"",
        // 上架商品弹窗
      addmerchandise:false,
      // 修改门头照
       revamplogVisibleDoor:false, 
       revamplogImageDoorUrl:"",
  // 编辑地图相关
  redactinerVisible:false,
  redactinkeyWord: '',
      // 添加店内分类
  insertBusinessDate:{},
      // 店内分类
   gridData: [],
// 店铺编辑
addondaroicrointow:[],
addondaroicroin:[],
addondaryCategoryIdDate:[],
formuladata:[],
outerVisiblepoer:false,
sehe:false,
child:"",
// 商品配送选择
goodsTypewhether:{
goodsTypewhether1:true,
 goodsTypewhether2:true,
},
      // 是否配送全选
      whether:{
      whether1:true,
       whether2:true,
      },
      indowfigureVisible:false,
      WindowfigureUrl:"",
      // 点击查看列表图片
           businessShowImgUrlVisible:false,
          businessImageUrl:"",
      // 设置提交数组
      formsetdate:{
        
      },
      // 二级分类
      secondaryCategoryIdDate:[],
      // 列表大图展示
      dialogimg:false,
      dialogimgsrc:"",
      // 店铺物流分类
   goodsStatusList: [
     {
          value: 4,
          label: '上门或者到店'
        },
       {
          value: 2,
          label: '商家自行配送'
        },
        {
          value: 1,
          label: '顺丰配送'
        }, {
          value: 0,
          label: '配送或自提'
        }
        
      ],
         mergoodsStatusListList: [
        {
          value: 0,
          label: "上架",
        },
        {
          value: 1,
          label: "下架",
        },
      ],
    redactbusinessAddressJson:[],
      // 查看经营许可证
      BusinessCertificateUrl:"",
    BusinessCertificateDoor:false,
      // 查看营业执照
        BusinessLicenseUrl:"",
    BusinessLicenseDoor:false,
      //查看国徽照
      ReversesideofidcardUrl:"",
    ReversesideofidcardDoor:false,
      // 查看身份证人像
       IdentityCardPortraitUrl:"",
    IdentityCardPortraitDoor:false,
      // 店内图片列表
      pxr:false,
      businessInPhotoindexList:[],
      businessInPhotoList:[],
      dialogVisiblePhoto:false,
      businessInPhotoUrl:"",
      // 查看门头照
      dialogVisibleDoor:false,
      dialogImageDoorUrl:"",
      // 查看logo图片
      dialogVisiblelog:false,
      dialogImagelogUrl:"",
      // 查询是否已经注册会员
      businessPhone:"",
      value1:false,
      childr:"",
      // 弹窗
      checked1:false,
      checked2:false,
    
      outerVisibler: false,
      innerVisibler: false,
      classifyVisible: false,
      redactondaroicroin:[],
      tableData: [
      ],
      // 添加地图相关
      searchOption:[],
         keyWord: '',
        longAndLat: '',
        map: null,
        mapMark: null,
        localSearch: '',
        textTip: '',
        isshowTip: false,
      
      ShopDialogVisible: false,
      selectedOptions: [],
      businessStatusdate:[
         {value:0,label:"下架"},
          {value:1,label:"营业中"},
           {value:2,label:"删除"},
            {value:3,label:"关店"},
      ],
    
      business:[
          `Sun Oct 10 2021 0:00:00 GMT+0800 (中国标准时间)`,
          `Sun Oct 10 2021 0:00:00 GMT+0800 (中国标准时间)`,
      ],
      // 设置展示图
      businessShowImgUrl:"",
      // 弹窗页面设置
      setType: 0,
       loading: false,
      // 新建区域弹窗
      innerVisible: false,
     
      form: {
        logisticsType:1,
        province:"内蒙古自治区",
        businessAddressJson:{
          address: "内蒙古自治区兴安盟乌兰浩特市都林街道金泽·时代广场",
          city: "兴安盟",
          district: "乌兰浩特市",
         latitude: 46.07998,
         longitude: 122.048705,
         province: "内蒙古自治区"
        },
        
businessEndTime: "20:00:00",
businessStartTime: "08:00:00",
        businessCertificatesJson:{}
      },
   obj:{},
      fileList: [],
      outerVisible:  false,
      radio: "1",
      commodityStatus: [
     
      ],
      merchantStatusList: [
       
      ],
      packageNumberAddStatus:false,
      redactityStatus:[],
      addommodityStatus:[],
      // 查找商品列表参数对象
      queryInfo: {
      categoryId:"",
      keyword:"",
      size:10,
      order:"desc",
      page:1,
      province:"",
      },
      formudate:{
businessCertificatesJson:{}
      },
      time: "",
 deliveryfeedata:{
logisticsTyp:"",
deliveryfeeTypeStatus:false,
 },

 businessInPhotoJ:[],
      total: 0,
  businessIdst:"",
  IdentityCardPortraitUrls:"",
  IdentityCardPortraitDoors:false,
  BusinessLicenseUrls:"", 
  BusinessLicenseDoors:false ,
  BusinessCertificateUrls:"",
BusinessCertificateDoors:false,
ReversesideofidcardUrls:"",
ReversesideofidcardDoors:false,
      distributionType:"",
    // 视频
        kiar:false,
      videoForm:{
        videoUploadId:"",
        Video:"",
      },
      addvideoPreviewImgUrl:"",
      videoFlag:false,
      videoUploadPercent:0,
      businessList:{
         businessId:"",
         goodsStatus:"",
         keyword:"",
         page:1,
         size:10,
         order:"desc"
      },
      businesstotal:"",
      // 规格设置
      specificationDate:[],
      goodsId:"",
      specification:false,
      addspecificationDate:{},
      redactspecificationDate:{},
      redactpecification:false,
      addspecification:false,
      businesslogisticsType:"",
      businessdistributionType:"",
      // 编辑商品数据
      goodsdate:{},
      onaddloding:false,
      previewUrl:"",
      ImgsJson:[],
       // 规则描述
      goodsDetailsJsons:[],
      contentc:"",
      goodsDetailsJsonion:"",
      addmerchandiser:false,
      rotationImgsJsonion:[],
      rotationImgsidion:[],
      slideshowInPhotoUrlion:"",
      slideshowVisiblePhotoion:false,
            videoPreviewImgUrl:"",
      addslideshowVisiblePhotovideo:"",
addslideshowInPhotoUrlvideo:false,
        addvideoForm:{
       Video:"",
      },
      handleDatet:{},
      goodsdatevideoPreviewImgUrl:"",
      revampGoodsReadUrlvideo:"",
      revampGoodsReadstustvideo:false,
      getContractDetai:false,
      getContractDeta:{
        contract:{}
      },
      
    }
    
  },
  mounted() {
      
  },
  created() {
    this.getGoodsList()
   
  },
  methods: {
   async getContractDetailns(e){
   const { data: date } = await getContractDetails({userId:e})
    if(date.code!==200)return this.$message.error(date.msg);
      this.getContractDeta=date.body
      this.getContractDetai=true
      if(this.getContractDeta.contract.signTime){
this.getContractDeta.contract.signTime=time(this.getContractDeta.contract.signTime)
      }else{
        this.getContractDeta.contract.signTime="暂无签约时间"
      }
       if(this.getContractDeta.contractOrders.updateTime){
this.getContractDeta.contractOrders.updateTime=time(this.getContractDeta.contractOrders.updateTime)
      }else{
        this.getContractDeta.contractOrders.updateTime="暂无支付时间"
      }  
      if(this.getContractDeta.settledOrders.updateTime){
this.getContractDeta.settledOrders.updateTime=time(this.getContractDeta.settledOrders.updateTime)
      }else{
        this.getContractDeta.settledOrders.updateTime="暂无支付时间"
      }  
 
    },
      onaddvideoForm(){
this.addvideoForm.Video=''
this.addvideoForm.videoUploadId=''
    },
    onvideoPreviewImgUrl(){
this.addvideoPreviewImgUrl=''
    },
    onvideoForm(){
this.videoForm.Video=''
this.videoForm.videoUploadId=''
    },
     imgcheckedpoc(file) {
      
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
    
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
     
            if (0.8<=(img.width/img.height)&&(img.width/img.height)<=1.5) {
               resolve(true)
            } else {
                 this.$message.error('图片比例宽高1比1左右图片');
             reject()
         
            }
          }
        }
      })
        },
        beforeAvatarUploadpoc(file){
    if (!(file.type === 'image/jpeg' || file.type ==='image/png')){
  this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
   return false
    }
   	if ( !(file.size / 1024 / 1024 < 5)) {
			this.$message.error('上传图片大小不能超过 5MB!');
			 return false
		}
 return this. imgcheckedpoc(file)
   
    },
         //删除商品阅览图
    handleRemoveGoodsReadvideo(){
      this.goodsdatevideoPreviewImgUrl=""
    },
    // 查看商品阅览图
     revampGoodsReadvideo(){
      
           this.revampGoodsReadUrlvideo=this.goodsdatevideoPreviewImgUrl
           this.revampGoodsReadstustvideo=true
       },
    // 上传视频封面
   async upGoodsReadvideo(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",6)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.goodsdatevideoPreviewImgUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
      // 删除轮播图
       addslideshowPictureCardRemoveInPhotoListvideo(){
this.videoPreviewImgUrl=""

    },
    // 查看轮播图
    addslideshowReversesideofidcardvideo(file){
    
   this.addslideshowInPhotoUrlvideo= true
   this.addslideshowVisiblePhotovideo=this.videoPreviewImgUrl

    },
     async upaddslidevideo(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",6)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
       
     this.videoPreviewImgUrl=res.body[0]
     
      this.$message.success("上传成功");
    },
    // 初始化编辑列表
    addmerchandisertur(){
      this.child=""
          this.childr=""
      this.contentc=""
      this.addmerchandiser=false
     this.handleDatet={}
    },
    // 保存编辑
async  onaddmerchandiseDateion(){

  if(this.logisticsType==6||this.logisticsType==7){
    if(!this.goodsTypewhether.goodsTypewhether1&&!this.goodsTypewhether.goodsTypewhether2) return this.$message.error("物流未选择")
  }
    if(this.addvideoForm.Video){
    if(!this.goodsdatevideoPreviewImgUrl&&!this.addvideoPreviewImgUrl){
      return this.$message.error('商品视频封面未上传');
    }
  }
  if(this.goodsdatevideoPreviewImgUrl||this.addvideoPreviewImgUrl){
     if(!this.addvideoForm.Video){
       return this.$message.error('商品橱窗图视频未上传');
     }
  }
if(!this.goodsdate.goodsName) return this.$message.error('未填写商品名称');
if(!this.goodsdate.businessCategoryId) return this.$message.error('未选择商品分类');
if(!this.goodsdate.goodsBeginTime||!this.goodsdate.goodsEndingTime) return this.$message.error('未选择商品日期');

if(!this.goodsdate.effectDays) return this.$message.error('未选购买有效期天数');
// if(this.goodsDetailsJsons.length==0) return this.$message.error('未选填写商品描述');
if(this.goodsdate.scribePrice-0<this.goodsdate.goodsPrice-0)return this.$message.error("成交价不得大于划线价");
 if(!  this.goodsdate.goodsPrice)return this.$message.error("未填写商品价格")
  if(! this.goodsdate.scribePrice)return this.$message.error("未填写划线价格")
  if(! this.goodsdate.stockNum)return this.$message.error("未填写库存量")
    if(!this.childr)return this.$message.error("未填写使用规则")
    if(!this.contentc)return this.$message.error("商品详情未填写")   
    if(this.rotationImgsJsonion.length>0){

      this.goodsdate.rotationImgsJson=this.rotationImgsJsonion.join(',')
    }else{
      
    this.goodsdate.rotationImgsJson = this.ImgsJson.join(',')
    }
     if(this.goodsdatevideoPreviewImgUrl){
   this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
     this.goodsdate.rotationImgsJson.unshift(this.goodsdatevideoPreviewImgUrl) 
     this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
    }else {
      if(this.addvideoPreviewImgUrl){
     this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
this.goodsdate.rotationImgsJson.unshift(this.addvideoPreviewImgUrl) 
this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
      }
       }
      if(this.addvideoForm.Video){
           this.goodsdate.rotationImgsJson= this.goodsdate.rotationImgsJson.split(",") 
     this.goodsdate.rotationImgsJson.unshift(this.addvideoForm.Video)
    this.goodsdate.rotationImgsJson=this.goodsdate.rotationImgsJson.join(',')
   }

//           this.addmerchandiseDate.goodsDetailsJson
//     this.goodsDetailsJsons.forEach((element,i) => {
//     const key="t"+i
//     const value=element
//       this.goodsdate.goodsDetailsJson[key]=value.t1
// })

          // this.goodsdate.goodsDetailsJson=JSON.stringify(this.goodsdate.goodsDetailsJson)
        this.goodsdate.useRuleJson= `<html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.childr}</body></html> `
this.goodsdate.goodsDetails= `s<html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.contentc}</body></html> `
this.goodsdate.goodsDetailsJson=""
   if(this.logisticsType==6){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.goodsdate.goodsType=6
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.goodsdate.goodsType=1
    }else{
      this.goodsdate.goodsType=3
    }
    } else if(this.logisticsType==7){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.goodsdate.goodsType=7
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.goodsdate.goodsType=4
    }else{
      this.goodsdate.goodsType=5
    }
    }
    console.log(this.goodsdate.goodsBeginTime);
 this.goodsdate.goodsBeginTime=this.goodsdate.goodsBeginTime.getTime()
this.goodsdate.goodsEndingTime =this.goodsdate.goodsEndingTime.getTime()

    this.onaddloding=true
    // this.goodsdate.rotationImgsJson= "http://oss.fuhangbeiyue.com/video/322b7132bf61498db346de31b0c698ab_1655689467469.mp4"
   const { data: res } = await updateGoods (this.goodsdate);
      if (res.code !== 200) return this.$message.error("编辑失败稍后重试");
          this.$message.success("编辑成功");
               this.addvideoPreviewImgUrl=""
               this.rotationImgsJsonion=[],
        this.addmerchandise=false
           this.onaddloding=false
           const { data: date } = await   searchGoodsSpecificationByGoodsId ({goodsId:this.goodsdate.id});
              for (let i = 0; i < date.body.length; i++) {
            if(date.body[i].specificationName=="默认"){
              this.obj={
                dealPrice:this.goodsdate.goodsPrice,
                goodsId:this.goodsdate.id,
                id:date.body[i].id,
                scribePrice:this.goodsdate.scribePrice,
                specificationName:"默认",
                stockNum:this.goodsdate.stockNum
              }
            
            }
          }
               this.contentpoui() 
           this.addmerchandisertur()
     this.checkmerchandiseVisible( this.businessList.businessId)
     
    },
    async  contentpoui(){
 const { data: data } = await updateGoodsSpecification (this.obj); 

 this.obj={}
    },
        // 删除描述
       remogoodsDetailsJsonion(e){
this.goodsDetailsJsons.splice(e,1)

    },
    //添加描述
    ongoodsDetaion(){
const obj={
  "t1":""
}
 this.goodsDetailsJsons.push(obj)
    },
     goodsTimer(){
      console.log(this.handleDatet);
 this.goodsdate.goodsBeginTime = this.handleDatet[0]
      this.goodsdate.goodsEndingTime = this.handleDatet[1]
    },
        // 显示进度
adduploadVideoProcess(event, file, fileList){
    this.videoFlag = true;
    this.videoUploadPercent = parseFloat(file.percentage.toFixed(0))
},
// 视频上传成功
async addhandleVideoSuccess(file) {    
     const formData = new FormData()
       formData.append("file", file.file);
      const { data: res } = await this.$http.post(`/api/platform/uploadVideo`,formData);
    if(res.code == 200){
      //  this.images.push(res.result.url)
        this.addvideoForm.Video = res.body;
    }else{
        this.$message.error('视频上传失败，请重新上传！');
    }
    },
     addvideoSaveToUrl(event) {
  
      this.video = event.raw;
    },
    // 验证视频大小
 
 addbeforeUploadVideo(file) {
this.headers.equipmentIdentification=uuid.v1(),
this.headers.clientId=window.localStorage.getItem('clientId'),
this.headers.equipmentType=getBrowserInfo()[0],
this.headers.equipmentName=getBrowserInfo()[0].split("/")[0],
this.headers.requestTime= `${new Date().getTime()}`,
this.headers.sign=md5(`${uuid.v1()}#${getBrowserInfo()[0].split("/")[0]}#${getBrowserInfo()[0]}#${md5(uuid.v1().slice(7,26))}#${ parseInt(new Date().getTime()/1000/180)}`),
this.headers.Authorization=window.sessionStorage.getItem('accessToken'),
this.headers.testToken="2gq72h2qrbhx256y0167uf5wd64ls55u"
this.headers.BG_DEBUG=0
    const isLt10M = file.size / 1024 / 1024  < 20;
    if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式');
        
        return false;
    }
    if (!isLt10M) {
        this.$message.error('上传视频大小不能超过20MB哦!');
        return false;
    }
    },
     // 删除轮播图
       slideshowPictureCardRemoveInPhotoListion(file){
this.rotationImgsidion.forEach((element,i) => {
 if(element==file.uid){
   this.rotationImgsJsonion.splice(i,1)
 this.rotationImgsidion.splice(i,1)
 } 
});
    },
     // 查看轮播图
    slideshowReversesideofidcardion(file){
     
this.rotationImgsidion.forEach((element,i) => {
 if(element==file.uid){
   this.slideshowInPhotoUrlion= this.rotationImgsJsonion[i]
   this.slideshowVisiblePhotoion=true
 }
 });
    },
// 上传轮播图
 async upslideshowion(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.rotationImgsidion.push(file.file.uid)
     this.rotationImgsJsonion.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
    },
         //删除商品阅览图
    handleRemoveGoodsReadion(){
      this.goodsdate.previewUrl=""
    },
    // 查看商品阅览图
     revampGoodsReadion(){
           this.revampGoodsReadUrl=this.goodsdate.previewUrl
           this.revampGoodsReadstust=true
       },
    // 上传商品阅览图
   async upGoodsReadion(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.goodsdate.previewUrl= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
     // 点击获取商品详情
   async searchGoodsByIds(ids,lat,long,businessIds){
    var rx = /<body[^>]*>([\s\S]+?)<\/body>/i;
const { data: res } = await searchGoodsById({id:ids,latitude:lat,longitude:long})
this.goodsdate=res.body;
this.goodsDetailsJsonion=[]
this.goodsDetailsJsons=[]
if(this.goodsdate.goodsDetailsJson){
this.goodsdate.goodsDetailsJson=JSON.parse(this.goodsdate.goodsDetailsJson)

for (const key in this.goodsdate.goodsDetailsJson) {
 this.goodsDetailsJsonion.push(this.goodsdate.goodsDetailsJson[key])
}
this.goodsDetailsJsonion.forEach(element => {
  const obj={
    "t1":element
  } 
  this.goodsDetailsJsons.push(obj)
});
}else{
  this.goodsDetailsJsons=[]
}

this.goodsdate.goodsBeginTime=new Date(this.goodsdate.goodsBeginTime)
this.goodsdate.goodsEndingTime=new Date(this.goodsdate.goodsEndingTime)
this.handleDatet=[this.goodsdate.goodsBeginTime,this.goodsdate.goodsEndingTime]
// this.queryInfo.time = dataFunction(this.time);
// goodsBeginTime
// goodsEndingTime

   const { data: date } = await searchBusinessCategoryListForPC ({businessId:businessIds,order:"asc"});
    if (res.code !== 200) return this.$message.error("获取商家店内分类错误");
    this.searchBusinessCategoryList=date.body
this.previewUrl=this.goodsdate.previewUrl
this.contentc= rx.exec(this.goodsdate.goodsDetails)[1]
this.childr= rx.exec(this.goodsdate.useRuleJson)[1]

this.rotationImgsJson=[]
this.goodsDetailsJson=[]
this.rotationImgsid=[]
 this.addvideoForm.Video=""
 this.addvideoPreviewImgUrl=""
this.ImgsJson=this.goodsdate.rotationImgsJson.split(",") 
if(this.ImgsJson[0].split('.')[3]=="mp4"){
 this.addvideoForm.Video=this.ImgsJson.splice(0,1)[0]
this.addvideoPreviewImgUrl=this.ImgsJson.splice(0,1)[0]
}
  this.goodsTypewhether.goodsTypewhether1=false
  this.goodsTypewhether.goodsTypewhether2=false
const { data: data } = await searchBusinessInfoById({id:businessIds});
this.logisticsType=data.body.logisticsType
if(this.goodsdate.goodsType==6||this.goodsdate.goodsType==7){
  this.goodsTypewhether.goodsTypewhether1=true
  this.goodsTypewhether.goodsTypewhether2=true
}else if(this.goodsdate.goodsType==1||this.goodsdate.goodsType==4){
   this.goodsTypewhether.goodsTypewhether1=true
}else if(this.goodsdate.goodsType==3||this.goodsdate.goodsType==5){
   this.goodsTypewhether.goodsTypewhether2=true
}
this.onaddloding=false
this.addmerchandiser=true
    },
        // 删除商品
    async deleter(id){
  const formData = new FormData()
  formData.append("ids", id);
  const { data: res } = await delGoods(formData)
  if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
  this.$message.success("删除成功");
     this.loading=false
  this.checkmerchandiseVisible( this.businessList.businessId)
},
        // 商品上下架
  async updateGoodStatust(ids,Status){
     const formData = new FormData()
       formData.append("id", ids);
        formData.append("goodsStatus", Status);
         const { data: res } = await this.$http.post(`/api/goods/updateGoodStatus`,formData);
if (res.code !== 200) return this.$message.error("商品状态更新失败请稍后重试");
 this.$message.success("商品状态更新成功");
this.checkmerchandiseVisible( this.businessList.businessId)
    },

        // 删除规格
   async delGoodsSpecifi(id,goodsId){
    if(this.specificationDate.length==1){
      return this.$message.error("规格不能为空");
    }
      const formData = new FormData()
  formData.append("ids", id);
       const { data: res } = await delGoodsSpecification (formData);
      if (res.code !== 200) return this.$message.error("删除规格失败请稍后重试");
       this.$message.success("删除规格成功");
       this.specificationVisible(goodsId)
    },
      // 点击确认编辑
      async redactspecificationDater(){
      if(!this.redactspecificationDate.specificationName)return  this.$message.error("请输入规格名称");
       if(!this.redactspecificationDate.dealPrice)return  this.$message.error("请输入成交价格");
        if(!this.redactspecificationDate.scribePrice)return  this.$message.error("请输入划线价格");
        if(this.redactspecificationDate.scribePrice-0<this.redactspecificationDate.dealPrice-0)return  this.$message.error("成交价不得大于划线价");
         if(!this.redactspecificationDate.stockNum)return  this.$message.error("请输入库存量");
       const { data: res } = await updateGoodsSpecification (this.redactspecificationDate); 
       if (res.code !== 200) return this.$message.error("添加规格失败请稍后重试");
          this.$message.success("添加规格成功");
      this.specificationVisible(this.redactspecificationDate.goodsId)
     this.redactpecification=false
    },
        // 编辑规格
    redactspecification(row){
    this.redactspecificationDate={
    dealPrice:row.dealPrice,
    goodsId: row.goodsId,
    id: row.id,
    scribePrice: row.scribePrice,
    specificationName: row.specificationName,
    stockNum: row.stockNum
}  
  
this.redactpecification=true
    },
        // 确认添加新规格
    async addspecificationDater(){
    this.addspecificationDate.goodsId= this.goodsId
    if(!this.addspecificationDate.specificationName)return  this.$message.error("请输入规格名称");
    if(!this.addspecificationDate.dealPrice)return  this.$message.error("请输入成交价格");
    if(!this.addspecificationDate.scribePrice)return  this.$message.error("请输入划线价格");
    if(this.addspecificationDate.scribePrice-0<this.addspecificationDate.dealPrice-0)return  this.$message.error("成交价不得大于划线价");
    if(!this.addspecificationDate.stockNum)return  this.$message.error("请输入库存量");
       const { data: res } = await insertGoodsSpecification (this.addspecificationDate); 
       if (res.code !== 200) return this.$message.error("添加规格失败请稍后重试");
       this.$message.success("添加规格成功");
     this.addspecificationDate={}
     this.specificationVisible(this.goodsId)
     this.addspecification=false
    },
        // 点击打开规格弹窗
       async specificationVisible(id){
    const { data: res } = await searchGoodsSpecificationByGoodsId ({goodsId:id});  
     if (res.code !== 200) return this.$message.error("获取规格信息失败请稍后重试");
     
    this.specificationDate=res.body
    this.goodsId=id
this.specification=true
    },
    // 店内商品模糊搜索 
     getGoodsListkow(){
this.businessList.page = 1;
this.checkmerchandiseVisible( this.businessList.businessId)
    },
        businessListChange(newpage){
this.businessList.page=newpage
this.checkmerchandiseVisible( this.businessList.businessId)

        },
      // businessListSizeChange
//获取店内商品列表
async checkmerchandiseVisible(ids){
  this.businessList.businessId=ids
const { data: res } = await  searchGoodsList(this.businessList);
this.businessData=res.body.list
this.businesstotal=res.body.total
const { data: date } = await searchBusinessInfoById({id:ids});
if (date.code !== 200) return this.$message.error("获取商家信息错误");
  this.businessdistributionType=date.body.distributionType 
  this.businesslogisticsType=date.body.logisticsType
  this.checkmermerchandise=true

},

    packageNumberAddStatu(){
if(this.packageNumberAddStatus){
  this.formsetdate.packageNumberAddStatus=1

}else{
  this.formsetdate.packageNumberAddStatus=0
}
    },
    // 是否支持配送
    isBargainingHandel(){
if(this.barginStatus){
  this.addmerchandiseDate.distributionStatus=1

}else{
  this.addmerchandiseDate.distributionStatus=0
}
    },
 
    // 删除视频
     onspx(){
       this.videoForm.Video=""
      this.videoFlag=true,
     this.videoUploadPercent=0
   
      },
    // 显示进度

uploadVideoProcess(event, file, fileList){
    this.videoFlag = true;
    this.videoUploadPercent = parseFloat(file.percentage.toFixed(0))
},
// 视频上传成功
async handleVideoSuccess(file) {    
  const formData = new FormData()
       formData.append("file", file.file);
      const { data: res } = await this.$http.post(`/api/platform/uploadVideo`,formData);
    if(res.code == 200){
      //  this.images.push(res.result.url)
        this.videoForm.Video = res.body;
      
   
    }else{
        this.$message.error('视频上传失败，请重新上传！');
    }
    },
     videoSaveToUrl(event) {
  
      this.video = event.raw;
    },
 
beforeUploadVideo(file) {
this.headers.equipmentIdentification=uuid.v1(),
this.headers.clientId=window.localStorage.getItem('clientId'),
this.headers.equipmentType=getBrowserInfo()[0],
this.headers.equipmentName=getBrowserInfo()[0].split("/")[0],
this.headers.requestTime= `${new Date().getTime()}`,
this.headers.sign=md5(`${uuid.v1()}#${getBrowserInfo()[0].split("/")[0]}#${getBrowserInfo()[0]}#${md5(uuid.v1().slice(7,26))}#${ parseInt(new Date().getTime()/1000/180)}`),
this.headers.Authorization=window.sessionStorage.getItem('accessToken'),
this.headers.testToken="2gq72h2qrbhx256y0167uf5wd64ls55u",
this.headers.BG_DEBUG=0
    const isLt10M = file.size / 1024 / 1024  < 20;
    if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
        this.$message.error('请上传正确的视频格式');
        
        return false;
    }
    if (!isLt10M) {
        this.$message.error('上传视频大小不能超过20MB哦!');
        return false;
    }
    },
    content(){

    },
    // 编辑商家信息
   async redactBusinessInfo(){
      if(!this.formuladata.district||this.formuladata.district=="区") return this.$message.error("入驻城市未填写完整");
       if(!this.formuladata.businessName) return this.$message.error("店铺名称还未填写");
       if(!this.formuladata.businessPhone) return this.$message.error("店铺电话不能为空");
        if(!this.formuladata.primaryCategoryId) return this.$message.error("平台一级分类未选择");
        if(!this.formuladata.categoryId) return this.$message.error("平台二级分类未选择");
       this.formuladata.businessAddressJson=JSON.stringify(this.formuladata.businessAddressJson)
        this.formuladata.businessCertificatesJson=JSON.stringify(this.formuladata.businessCertificatesJson)
       if(this.businessInPhotoList.length>0){
 this.formuladata.businessInPhotoJosn=this.businessInPhotoList.join(',')
        }
         this.sehe=true
         
   if(this.formuladata.logisticsType==5||this.formuladata.logisticsType==7){
        
  this.formuladata.maxDistance=999999999
 }

 const { data: res } = await updateBusinessInfo(this.formuladata);
  this.formuladata.businessAddressJson=JSON.parse(this.formuladata.businessAddressJson)
 this.formuladata.businessCertificatesJson=JSON.parse(this.formuladata.businessCertificatesJson)

if (res.code !== 200) return this.$message.error("修改失败请稍后重试");

this.getGoodsList()
this.outerVisiblepoer=false
    },
       // 删除身份证人像
        handleRemoveReversesideofidcards(){
this.formuladata.businessCertificatesJson.legal_person_car_img_reverse=""

    },
     handleReversesideofidcards(){
  this.ReversesideofidcardUrls=this.formuladata.businessCertificatesJson.legal_person_car_img_reverse
    this.ReversesideofidcardDoors=true
    },
      async  upReversesideofidcards(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formuladata.businessCertificatesJson.legal_person_car_img_reverse= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
        handleBusinessCertificates(){
  this.BusinessCertificateUrls=this.formuladata.businessCertificatesJson.certificate_img
    this.BusinessCertificateDoors=true
    },
      // 删除经营许可证
        handleRemoveBusinessCertificates(){
this.formuladata.businessCertificatesJson.certificate_img=""
    },
         async  upBusinessCertificates(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formuladata.businessCertificatesJson.certificate_img= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    handleRemoveBusinessLicenses(){
this.formuladata.businessCertificatesJson.licence_img=""
    },
 
        // 查看编辑营业执照
    handleBusinessLicenses(){
    
  this.BusinessLicenseUrls=this.formuladata.businessCertificatesJson.licence_img
    this.BusinessLicenseDoors=true
    },
    // 编辑营业执照
     
  async  upBusinessLicenses(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      
      this.formuladata.businessCertificatesJson.licence_img= res.body[0].split("?")[0];
      this.$message.success("上传成功");

    },
    handleRemoveIdentityCardPortraits(){
this.formuladata.businessCertificatesJson.legal_person_car_img_front=""
    },
    // 查看编辑的人像照
    
    handleIdentityCardPortraits(){
  this.IdentityCardPortraitUrls=this.formuladata.businessCertificatesJson.legal_person_car_img_front
    this.IdentityCardPortraitDoors=true
    },
    // 编辑人像照
   async  upIdentityCardPortraits(file){
    const formData = new FormData()
        formData.append("file", file.file);
       formData.append("type",1)
     
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formuladata.businessCertificatesJson.legal_person_car_img_front= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    onremomerchandiseDate(){
 this.initialize()
    },
    addonprovince(data){
      if(data.value=="省"){
this.formuladata.province=""
      }else{
this.formuladata.province=data.value
      }


    },
    // 商品上架
  async  onaddmerchandiseDate(){
  

  if(!this.addmerchandiseDate.goodsName)return this.$message.error("商品名称为空");
  if(!this.addmerchandiseDate.previewUrl)return this.$message.error("商品阅览图为空");
  if(this.slideshowInPhotoList.length==0)return this.$message.error("商品橱窗图为空");
    if(this.videoForm.Video){
    if(!this.videoPreviewImgUrl){
      return this.$message.error('商品视频封面为上传');
    }
  }
  if(this.videoPreviewImgUrl){
     if(!this.videoForm.Video){
       return this.$message.error('商品橱窗图视频未上传');
     }
  }
  if(!this.addmerchandiseDate.businessCategoryId)return this.$message.error("商品分类未选择")
  if(!this.addmerchandiseDate.goodsBeginTime||!this.addmerchandiseDate.goodsEndingTime)return this.$message.error("售卖周期未填写完整")
  if(! this.addmerchandiseDate.effectDays)return this.$message.error("购买有效天数")
  if(!  this.addmerchandiseDate.goodsPrice)return this.$message.error("未填写商品价格")
  if(! this.addmerchandiseDate.scribePrice)return this.$message.error("未填划线价格")
      if(this.addmerchandiseDate.scribePrice-0<this.addmerchandiseDate.goodsPrice-0)return this.$message.error("成交价不得大于划线价");
  if(! this.addmerchandiseDate.stockNum)return this.$message.error("未填写库存量")
if(!this.goodsTypewhether.goodsTypewhether1&&!this.goodsTypewhether.goodsTypewhether2&&!this.addmerchandiseDate.goodsType) return this.$message.error("物流未选择")
  if(!this.childr)return this.$message.error("未填写使用规则")
  if(!this.contentrc)return this.$message.error("商品详情未填写")
           if(this.videoForm.Video){
this.slideshowInPhotoList.unshift(this.videoPreviewImgUrl)
this.slideshowInPhotoList.unshift(this.videoForm.Video)
           }
           
     this.addmerchandiseDate.rotationImgsJson=this.slideshowInPhotoList.join(',')
     this.addmerchandiseDate.useRuleJson= ` <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.childr}</body></html> `
this.addmerchandiseDate.goodsDetails= `  <html lang="en"><head><meta charset="utf-8"><title></title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.contentrc}</body></html> `
this.addmerchandiseDate.goodsDetailsJson=""
// this.goodsDetailsJson.forEach((element,i) => {
//     const key="t"+i
//     const value=element
//       this.addmerchandiseDate.goodsDetailsJson[key]=value.t1
// })
// this.addmerchandiseDate.goodsDetailsJson=JSON.stringify(this.addmerchandiseDate.goodsDetailsJson)
    if(this.logisticsType==6){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.addmerchandiseDate.goodsType=6
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.addmerchandiseDate.goodsType=1
    }else{
      this.addmerchandiseDate.goodsType=3
    }
    } else if(this.logisticsType==7){
    if(this.goodsTypewhether.goodsTypewhether1&&this.goodsTypewhether.goodsTypewhether2){
this.addmerchandiseDate.goodsType=7
    }else if(this.goodsTypewhether.goodsTypewhether1){
this.addmerchandiseDate.goodsType=4
    }else{
      this.addmerchandiseDate.goodsType=5
    }
    }

this.addmerchandiseDate.goodsStatus=0
this.sehe=true
console.log(this.addmerchandiseDate);
 const { data: res } = await insertGoods(this.addmerchandiseDate);
  // if(res.code!==200)return this.$message.error("上架商品失败请稍后重试")
   this.checkmerchandiseVisible( this.businessList.businessId)
   this.sehe=false
   const Jsonc={
  dealPrice:res.body.goodsPrice,
  goodsId: res.body.id,
  scribePrice: res.body.scribePrice,
  specificationName: "默认",
 stockNum:res.body.stockNum
  }

  const { data: date } = await insertGoodsSpecification(Jsonc); 
if(date.code!==200)return this.$message.error(res.msg)
 this.initialize()
    
    },
    redactinerVisibler(){
       this.formuladata.businessAddressJson=this.redactbusinessAddressJson
       this.redactinerVisible= false
    },
    // 初始化商品列表
initialize(){
     this.goodsDetailsJson=[]
     this.slideshowInPhotoUrl=""
     this.slideshowVisiblePhoto=false
     this. slideshowInPhotoindexList=[]
     this. slideshowInPhotoList=[]
      this. revampGoodsReadUrl=""
          this. revampGoodsReadstust=false
          this.goodsTypewhether.goodsTypewhether1=true
          this.goodsTypewhether.goodsTypewhether2=true
     
     this.contentr=""
     this.contentrc=""
     this.child=""
     this.childr=""
       this.searchBusinessCategoryList=[]
      // 上架商品数据
this.videoForm.Video=""
this.videoPreviewImgUrl=""
     this. addmerchandiseDate={
goodsDetailsJson:{}
      }
        // 上架商品弹窗
        
      this.addmerchandise=false
},
    // 删除描述
    remogoodsDetailsJson(e){
this.goodsDetailsJson.splice(e,1)
    },
    // 添加描述
    ongoodsDeta(){
   this.goodsDetailsJson.push({t1:""})

    },
    maploadin(){

    },
    // 一级分类
   async   redactSelectGoods(data){
     const { data: res } = await getScondaryCategoryList({id:data});
      this.redactityStatus=res.body

    },
    // 编辑获取城市一级分类列表

 async  getGoodCategoryLis(id){
if(id=="市"){
      this.formuladata.primaryCategoryId=""
           this.redactondaroicroin=[]
              this.formuladata.categoryId=""
               this.redactityStatus=[]
}else{
const { data: res } = await getGoodCategoryListByCityName({pid:0,cityName:id});
     this.redactondaroicroin=res.body
} 
   },
    // 入驻省
    distpickerprovince(data){
      if(data.value=="省"){
 this.form.province=""
      }else{
         this.form.province=data.value
      }

 
    },
      // 删除轮播图
       slideshowPictureCardRemoveInPhotoList(file){
this.slideshowInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.slideshowInPhotoindexList.splice(i,1)
 this.slideshowInPhotoList.splice(i,1)
 } 
});
    },
    // 查看轮播图
    slideshowReversesideofidcard(file){
this.slideshowInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.slideshowInPhotoUrl= this.slideshowInPhotoList[i]
   this.slideshowVisiblePhoto=true
 }
 });
    },
    // 上传轮播图
   async upslideshow(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.slideshowInPhotoindexList.push(file.file.uid)
     this.slideshowInPhotoList.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
    },
      //删除商品阅览图
    handleRemoveGoodsRead(){
      this.addmerchandiseDate.previewUrl=""
    },
    // 查看商品阅览图
     revampGoodsRead(){
           this.revampGoodsReadUrl=this.addmerchandiseDate.previewUrl
           this.revampGoodsReadstust=true
       },
    // 上传商品阅览图
   async upGoodsRead(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.addmerchandiseDate.previewUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    goodsTime(){
      console.log(this.handleDate[0],this.handleDate[1]);
      this.addmerchandiseDate.goodsBeginTime = this.handleDate[0]
      this.addmerchandiseDate.goodsEndingTime = this.handleDate[1]
      
    },
    // 打击打开上架商品弹窗
  async   addmerchandiseVisible(ids,distributionType,logisticsType){
    
   const { data: res } = await searchBusinessInfoById({id:ids});
if (res.code !== 200) return this.$message.error("获取商家信息错误");

   this.addmerchandiseDate.userId=res.body.userId
     this.addmerchandiseDate.businessId=res.body.id
     this.addmerchandiseDate.addressJson=res.body.businessAddressJson
     this.addmerchandiseDate.categoryId=res.body.categoryId
     this.addmerchandiseDate.businessName=res.body.businessName
     this.addmerchandiseDate.goodsType=res.body.logisticsType
         this.addmerchandiseDate.categoryId= res.body.categoryId
    this.addmerchandiseDate.primaryCategoryId=res.body.primaryCategoryId
     this.logisticsType=logisticsType
    this.distributionType=distributionType
    const { data: date } = await searchBusinessCategoryListForPC ({businessId:ids,order:"asc"});
    if (res.code !== 200) return this.$message.error("获取商家店内分类错误");
    this.searchBusinessCategoryList=date.body
    this.sehe=false
    this.handleDate=undefined
    this.addmerchandise=true
    },
    
    // 修改门店logo
   async  revampLoadShopLogo(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formuladata.businessLogo= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
    // 删除门头照
    revampPictureCardRemoveDoor(){
this.formuladata.doorHeadPhoto=""
this.formuladata.doorTopPhotoJson==""
    },
    // 查看门头照
      revampPictureCardPreviewDoor(){
           this.revamplogImageDoorUrl=this.formuladata.doorHeadPhoto
           this.revamplogVisibleDoor=true
       },

    // 修改门头照
  async   revampLoadShopDoor(file){
 const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formuladata.doorHeadPhoto= res.body[0].split("?")[0];
     this.formuladata.doorTopPhotoJson= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
        searchcmpi (e,m) {
        
        let _this = this;
        console.log(window.AMap);
        window.AMap.plugin('AMap.PlaceSearch', function () {
          var autoOptions = {
            city: '全国',
          
            map: _this.map, // 展现结果的地图实例
            pageSize: 10, // 单页显示结果条数
            pageIndex: 1, // 页码
            autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          };
          var placeSearch = new window.AMap.PlaceSearch(autoOptions);
          placeSearch.search(e, function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            _this.searchOption=result.poiList.pois
          
          });
        });
      },
     // 高德选择相关地地区
      searchcnpi(e,m){   
   const _this=this
 window.AMap.plugin('AMap.PlaceSearch', function () {
var autoOptions = {
            city: '全国',
            
            map: _this.map, // 展现结果的地图实例
            pageSize: 10, // 单页显示结果条数
            pageIndex: 1, // 页码
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          };
          var placeSearch = new window.AMap.PlaceSearch(autoOptions);
          placeSearch.search(e, function (status, result) {
                 // 搜索成功时，result即是对应的匹配数据        
              if (result.poiList.pois.length > 0) {
                 _this.searchOption=[]
                let lng = result.poiList.pois[0].location.lng;
                let lat = result.poiList.pois[0].location.lat;
                _this.longAndLat = lng + ',' + lat;
                 _this.Geocodepo(lng, lat)
                _this.addMarker(lng, lat);
               
              } else {
                _this.$message({
                  message: '没有查询到对应的地址',
                  type: 'warning'
                });
                  _this.searchOption=[]
              }
          
          });
        });
     
      },
    // 地图编辑
    redactVisibles(){
    this. redactinkeyWord = this.bmapSearMsg;
      window.onLoadMap = () => {
        this.redacty();
      };
       asyncJS('https://webapi.amap.com/maps?v=1.4.15&key=fe4f84fe1582d0a85a4e64a6e9f3eeb0&callback=onLoadMap');
this.redactinerVisible=true
  
  },
      redacty () {
        this.map = new window.AMap.Map('container', {
          resizeEnable: true,
           zoom: 20,
          center:[this.formuladata.businessAddressJson.longitude,this.formuladata.businessAddressJson.latitude],
          });
       
          let _this = this;
  // 缩放组件
        window.AMap.plugin('AMap.ToolBar',function(){//异步加载插件
        var toolbar = new AMap.ToolBar();
        _this.map.addControl(toolbar);

    });
          // 为地图注册click事件获取鼠标点击出的经纬度坐标
          this.map.on('click', function (e) {
            let lng = e.lnglat.getLng();
            let lat = e.lnglat.getLat();
              _this.longAndLat = lng + ',' + lat
              _this.Geocodepo(lng, lat)
              _this.addMarker(lng, lat);
             
          });
          // 初始化地图标记点
            this.Geocodepo(this.formuladata.businessAddressJson.longitude,this.formuladata.businessAddressJson.latitude)
            this.addMarker(this.formuladata.businessAddressJson.longitude,this.formuladata.businessAddressJson.latitude);
      },
  
    innerVisibleroput(){
this.insertBusinessDate.businessId=this.businessIdst
this.innerVisibler=true

    },
     Geocodepo(lng, lat){  

      let _this = this;       // 地图逆编码获取信息
   AMap.plugin('AMap.Geocoder', function() {
  var geocoder = new AMap.Geocoder({
    city: '010'
  })
  var lnglat = [lng, lat]
  geocoder.getAddress(lnglat, function(status, result) {
    if (status === 'complete' && result.info === 'OK') {
     if(result.regeocode.addressComponent.city){
  var obj={
     province:result.regeocode.addressComponent.province,
     city:result.regeocode.addressComponent.city,
     district:result.regeocode.addressComponent.district,
     latitude:lat,
     longitude:lng,
     address:result.regeocode.formattedAddress
   }
     }else{
      var obj={
     province:result.regeocode.addressComponent.province,
     city:result.regeocode.addressComponent.province,
     district:result.regeocode.addressComponent.district,
     latitude:lat,
     longitude:lng,
     address:result.regeocode.formattedAddress
   }
     }
     _this.formuladata.businessAddressJson=obj
    }
  })
})

   },
    // 点击删除店内分类
   async  remordactVisibler(id,businessId){
      const formData = new FormData()
       formData.append("ids", id);
 const { data: res } = await delBusinessCategory(formData);
   if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
   this.$message.success('删除成功')
  this.onclassifyVisible(businessId)

    },

    // 点击打开分类编辑
    redactVisibler(ids,businessI,categoryNam,order){
      
     const obj={
       id:ids,
       businessId:businessI,
       categoryName:categoryNam,
       orders:order
     }
     this.insertBusinessDate=obj
     this.innerVisibler=true
    },
    // 点击插入分类
  async  addinsertBusiness(){
    if(!this.insertBusinessDate.categoryName)return this.$message.error("未填写店内分类名称");
    if(!this.insertBusinessDate.orders)return this.$message.error("未填写排序");
    const { data: res } = await insertBusinessCategory(this.insertBusinessDate);
    if (res.code !== 200) return this.$message.error("添加失败请稍后重试");
    this.onclassifyVisible(this.insertBusinessDate.businessId)
    this.insertBusinessDate={}
    this.innerVisibler=false
this.$message.success('添加成功')
    },
    // 点击分类设置
   async  onclassifyVisible(e){
   const { data: res } = await   searchBusinessCategoryListForPC({businessId:e,order:"asc"});
   if (res.code !== 200) return this.$message.error("获取店内信息失败请稍后重试");
   this.gridData=res.body
    this.businessIdst=e
    this.classifyVisible = true 
    },
      
    // 启用商户
      async  enableBusi(e,i){
   const { data: date } = await  userparticulars({userId:i});
if (date.code !== 200) return this.$message.error("获取用户信息失败请稍后重试");
   date.body.userIdentity=1
  const { data: obj } = await adminUpdate(date.body)
  if (obj.code !== 200) return this.$message.error("修改用户信息失败请稍后重试");
const { data: res } = await enableBusiness({id:e,businessStatus:1});
   if (res.code !== 200) return this.$message.error("启用失败请稍后重试");
   this.loading=false
    this.$message.success("启用成功");
 this.getGoodsList()
  },
  // 停用商户
  async  enableBusir(e){
const { data: res } = await enableBusiness({id:e,businessStatus:0});
if (res.code !== 200) return this.$message.error("停用失败请稍后重试");
   this.loading=false
 this.getGoodsList()
  
  },
  async  getSelectGoodssepiu(e){
const { data: res } = await getCategoryList({code:e});
this.addondaroicroin=[]
 res.body.forEach(element => {
      if(element.pid==0){
     this.addondaroicroin.push(element)
      }
    });
    },
    // 选择一级分类
   async getSelectGoodspoe(e){
const { data: res } = await getScondaryCategoryList({id:e});
this.addondaryCategoryIdDate=res.body
    },
    // 入驻一级分类
  async getCategorywr(e){
const { data: res } = await getCategoryList({code:e});
 
              this.addondaryCategoryIdDate=[]
this.addommodityStatus=[]
 res.body.forEach(element => {
      if(element.pid==0){
     this.addommodityStatus.push(element)
      }
    });
  },
    // 修改城市
    formulChecked(data){
     
      this.formuladata.province=data.province.value
      this.formuladata.city=data.city.value
       this.formuladata.district=data.area.value
      this.getGoodCategoryLis(this.formuladata.city)
       
    },
  //  点击编辑
 async  getformula(ids,cityName){
const { data: res } = await searchBusinessInfoById({id:ids});
if (res.code !== 200) return this.$message.error("获取商家信息错误");
this.formuladata=res.body
this.getGoodCategoryLis(cityName)
this.formuladata.businessAddressJson=JSON.parse(res.body.businessAddressJson)
this.redactbusinessAddressJson=this.formuladata.businessAddressJson
this.businessInPhotoJ=res.body.businessInPhotoJosn.split(",") 
this.formuladata.businessCertificatesJson=JSON.parse(res.body.businessCertificatesJson)
this.formudate.businessCertificatesJson.legal_person_car_img_reverse=this.formuladata.businessCertificatesJson.legal_person_car_img_reverse
this.formudate.businessCertificatesJson.licence_img=this.formuladata.businessCertificatesJson.licence_img
this.formudate.businessCertificatesJson.certificate_img=this.formuladata.businessCertificatesJson.certificate_img
this.formudate.businessCertificatesJson.legal_person_car_img_front=this.formuladata.businessCertificatesJson.legal_person_car_img_front
this.formudate.doorHeadPhoto=res.body.doorHeadPhoto
this.formudate.businessLogo=res.body.businessLogo
//  await this.getSelectGoodssepiu(e)
 this.businessInPhotoindexList=[]
 this.businessInPhotoList=[]
this.redactSelectGoods(this.formuladata.primaryCategoryId)
this.sehe=false
this.outerVisiblepoer=true
  },
// 保存设置
async addformsetdate(){
   if (!this.formsetdate.shopAveragePrice) return this.$message.error("人均平均价格还未填写")
   if (!this.formsetdate.businessShowImgUrl) return this.$message.error("推荐店铺图未空")
  //  if (!this.formsetdate.minConsumptionAmount) return this.$message.error("未填写最低消费")
  //  配送验证
   if((this.formsetdate.logisticsType==1||this.formsetdate.logisticsType==6)&&this.formsetdate.distributionType==1&&!this.formsetdate.maxDistance)return this.$message.error("未填写商家配送费")
  
// 快递验证
if(this.formsetdate.logisticsType===2){
  if(!this.formsetdate.expressFee) return this.$message.error("未填写快递费")
    if(!this.formsetdate.expressName) return this.$message.error("未填写快递名称")
}
// 上门服务验证
if((this.formsetdate.logisticsType===7||this.formsetdate.logisticsType===5)&&!this.formsetdate.maxDistance)return this.$message.error("未填写上门服务距离")
//  if (!this.whether.whether1&&!this.whether.whether2) return this.$message.error("物流服务还未选择");
if(this.formsetdate.packageNumberAddStatus){
  this.formsetdate.packageNumberAddStatus=1
}else{
   this.formsetdate.packageNumberAddStatus=0
}
if(this.formsetdate.deliveryfeeTypeStatus){
  this.formsetdate.deliveryfeeTypeStatus=1
}else{
   this.formsetdate.deliveryfeeTypeStatus=0
}
 
 if((this.formsetdate.logisticsType==1||this.formsetdate.logisticsType==6)&&this.formsetdate.distributionType==0){
this.formsetdate.maxDistance=10
 }
 this.sehe=true
const { data: res } = await  updateBusinessInfo(this.formsetdate);
if (res.code !== 200) return this.$message.error(res.msg);
this.outerVisibler=false
   this.sehe=false
this.getGoodsList()
},
       handlebindowfigureImg(){
      this.indowfigureVisible=true
         this.WindowfigureUrl=this.formsetdate.WindowfigureUrl
      },
    handleindowfigureImgRemove(){
this.formsetdate.WindowfigureUrl=""
    },
    // 上传橱窗图
   async upWindowfigureUrl(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formsetdate.WindowfigureUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 删除推荐列表图
    handlebusinessShowImgRemove(){
    this.formsetdate.businessShowImgUrl=""
    },
       // 点击查看图片
      handlebusinessShowImgUrl(){
      this.businessShowImgUrlVisible=true
         this.businessImageUrl=this.formsetdate.businessShowImgUrl
      },
      
    // 上传推荐列表图
   async upbusinessShowImgUrl(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.formsetdate.businessShowImgUrl= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 时间选择
   
        shopTime(){
       let value1=dataFunction(this.business[0])
      this.formsetdate.businessStartTime=value1.split(' ', 5)[1]
       let value2=dataFunction(this.business[1])
           this.formsetdate.businessEndTime=value2.split(' ', 5)[1]
    },
    // 点击设置获取详情
   async onset(ids){
const { data: res } = await searchBusinessInfoById({id:ids});
if (res.code !== 200) return this.$message.error("获取商家信息错误");
this.formsetdate=res.body
if(this.formsetdate.businessEndTime&&this.formsetdate.businessStartTime){
    this.business = [
        `Sun Oct 10 2021 ${this.formsetdate.businessStartTime} GMT+0800 (中国标准时间)`,
            `Sun Oct 10 2021 ${this.formsetdate.businessEndTime} GMT+0800 (中国标准时间)`,
        ];
}else{
    this.business = [
        `Sun Oct 10 2021 00:00:00 GMT+0800 (中国标准时间)`,
            `Sun Oct 10 2021 00:00:00 GMT+0800 (中国标准时间)`,
        ];
  this.formsetdate.businessEndTime="00:00:00"
  this.formsetdate.businessStartTime="00:00:00"
} 
if(!this.formsetdate.packageNumberAddStatus==0){
  this.formsetdate.packageNumberAddStatus=true
}else{
   this.formsetdate.packageNumberAddStatus=false
}
if(!this.formsetdate.deliveryfeeTypeStatus==0){
  this.formsetdate.deliveryfeeTypeStatus=true
}else{
   this.formsetdate.deliveryfeeTypeStatus=false
}
  this.businessShowImgUrl=this.formsetdate.businessShowImgUrl
  this.sehe=false
this.outerVisibler=true

    },
    // 状态搜索
    getSelectGoodsStatus(e){
     this.loading=false
this.queryInfo.page=1
this.getGoodsList()
    },
    getsecondaryCategoryId(){
        this.loading=false
this.queryInfo.page=1
this.getGoodsList()
    },
    // 二级分类
   async getSelectGoodsListStatus(e){
const { data: res } = await getScondaryCategoryList({id:e});
   this.loading=false
this.secondaryCategoryIdDate=res.body
this.getGoodsList()
    },
  //  一级分类
  async getCategory(e){
const { data: res } = await getCategoryList({code:e});
this.queryInfo.categoryId=""
this.secondaryCategoryIdDate=[]
this.queryInfo.secondaryCategoryId=""
this.commodityStatus=[]
 res.body.forEach(element => {
      if(element.pid==0){
     this.commodityStatus.push(element)
      }
    });
},
    // 店铺搜索
    onipukeyword(){
         this.loading=false
this.queryInfo.page=1
this.getGoodsList()
    },
    // 手机号查询
    oniputmobile(){
         this.loading=false
this.queryInfo.page=1
this.getGoodsList()
    },
    // 列表大图展示
    ondialogimgsrc(data){
this.dialogimgsrc=data
this.dialogimg=true
    },
    distpickercity(data){
      if(data.code){
this.getCategorywr(data.code.substring(0,4))
      }else{
        this.getCategorywr("")
      }

    },
    // 城市选择
    addressChecked(data){
      this.form.province=data.province.value
       this.form.city=data.city.value
       this.form.district=data.area.value
     
       
    },
    // 上传入驻信息
async addEnterlist(){
if(!this.businessPhone) return this.$message.error("手机号不可为空");
if(!this.form.userId) return this.$message.error("用户还未注册用户请app注册完成后再填写入驻信息");
// if(!this.form.city) return this.$message.error("入驻城市还未填写");
if(!this.form.district||this.form.district=="区") return this.$message.error("入驻城市未填写完整");
if(!this.form.businessName) return this.$message.error("店铺名称还未填写");
if(!this.form.logisticsType) return this.$message.error("还未选择店铺类型");
if(!this.form.businessPhone) return this.$message.error("店铺电话不能为空");
if(!this.form.primaryCategoryId||!this.form.categoryId) return this.$message.error("平台分类未填写完整");
if(!this.form.doorHeadPhoto) return this.$message.error("还未上传门头照");
if(!this.form.businessLogo) return this.$message.error("还未上传Logo");
if(this.businessInPhotoList.length===0) return this.$message.error("还未上传店内照");
if(!this.form.businessCertificatesJson.legal_person_car_img_front) return this.$message.error("身份证正面照未上传");
if(!this.form.businessCertificatesJson.legal_person_car_img_reverse) return this.$message.error("身份证反面照未上传");
if(!this.form.businessCertificatesJson.licence_img) return this.$message.error("营业执照未上传");
if(!this.form.businessCertificatesJson.certificate_img) return this.$message.error("经营许可证为上传");
if(!this.form.packingFee){
  this.form.packingFee=0
}
if(!this.form.packingFee){
  this.form.estimateDeliveryFee=0
}
this.form.businessInPhotoJosn=this.businessInPhotoList.join(',')
 this.form.businessAddressJson=JSON.stringify(this.form.businessAddressJson)
 this.form.businessCertificatesJson=JSON.stringify(this.form.businessCertificatesJson)
 this.sehe=true
  if(this.form.logisticsType==5||this.form.logisticsType==7){
  this.form.maxDistance=999999999
 }
 this.form.businessStatus=0
const { data: res } = await   insertBusinessInfo(this.form);
 this.form.businessAddressJson=JSON.parse(this.form.businessAddressJson)
 this.form.businessCertificatesJson=JSON.parse(this.form.businessCertificatesJson)
 if(this.form.logisticsType==5||this.form.logisticsType==7){
  this.form.maxDistance=999999999
 }
 if (res.code !== 200) return this.$message.error(res.msg);
 
   this.loading=false
 this.outerVisible=false
 this.$message.success('入驻成功')
this.getGoodsList()
    },
 
      // 查看经营许可证
     handleBusinessCertificate(){
  this.BusinessCertificateUrl=this.form.businessCertificatesJson.certificate_img
    this.BusinessCertificateDoor=true
    },
    // 删除经营许可证
        handleRemoveBusinessCertificate(){
this.form.businessCertificatesJson.certificate_img=""
    },
    // 上传经营许可证
  async  upBusinessCertificate(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.businessCertificatesJson.certificate_img= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
        // 查看营业执照
    handleBusinessLicense(){
  this.BusinessLicenseUrl=this.form.businessCertificatesJson.licence_img
    this.BusinessLicenseDoor=true
    },
    // 删除营业执照
        handleRemoveBusinessLicense(){
this.form.businessCertificatesJson.licence_img=""
    },
    // 上传营业执照
  async  upBusinessLicense(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.businessCertificatesJson.licence_img= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
     // 查看身份证国徽
    handleReversesideofidcard(){
  this.ReversesideofidcardUrl=this.form.businessCertificatesJson.legal_person_car_img_reverse
    this.ReversesideofidcardDoor=true
    },
    // 删除身份证国徽
        handleRemoveReversesideofidcard(){
this.form.businessCertificatesJson.legal_person_car_img_reverse=""
    },
    // 上传身份证国徽
  async  upReversesideofidcard(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.businessCertificatesJson.legal_person_car_img_reverse= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 查看身份证人像
    handleIdentityCardPortrait(){
  this.IdentityCardPortraitUrl=this.form.businessCertificatesJson.legal_person_car_img_front
    this.IdentityCardPortraitDoor=true
    },
    // 删除身份证人像
        handleRemoveIdentityCardPortrait(){
this.form.businessCertificatesJson.legal_person_car_img_front=""
    },
    // 上传身份证人像
  async  upIdentityCardPortrait(file){
     const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.businessCertificatesJson.legal_person_car_img_front= res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 查看 店内图片 
    handlePictureCardPreInPhotoList(file){
this.businessInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.businessInPhotoUrl= this.businessInPhotoList[i]
   this.dialogVisiblePhoto=true
 }
 });
    },
       // 删除店内图片
    handlePictureCardRemoveInPhotoList(file){
this.businessInPhotoindexList.forEach((element,i) => {
 if(element==file.uid){
   this.businessInPhotoindexList.splice(i,1)
 this.businessInPhotoList.splice(i,1)
 } 
});

    },
    // 上传店内图片
   async handlePictureCardPreviewStore(file){
    const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
     this.businessInPhotoindexList.push(file.file.uid)
     this.businessInPhotoList.push(res.body[0].split("?")[0])
      this.$message.success("上传成功");
      },
    // 查看门头照
    handlePictureCardPreviewDoor(){
  this.dialogImageDoorUrl=this.form.doorHeadPhoto
    this.dialogVisibleDoor=true
    },
    // 删除门头照
    handlePictureCardRemoveDoor(){
   this.form.doorHeadPhoto=""
   this.form.doorTopPhotoJson=""
    },
  //  上传门头照
     async  upLoadShopDoor(file) {
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.doorHeadPhoto= res.body[0].split("?")[0];
      this.form.doorTopPhotoJson=res.body[0].split("?")[0];
      this.$message.success("上传成功");
    },
    // 点击查看logo
    handlePictureCardPreviewlogo(){
   this.dialogImagelogUrl=this.form.businessLogo
    this.dialogVisiblelog=true
  
    },
    // 删除logo图片
    handlePictureCardRemoveLogo(){
this.form.businessLogo=""
    },
  
    // 上传图片验证
    beforeAvatarUpload(file){

    if (!(file.type === 'image/jpeg' || file.type ==='image/png')){
  this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
   return false
    }
   	if ( !(file.size / 1024 / 1024 < 5)) {
			this.$message.error('上传图片大小不能超过 5MB!');
			 return false
       	}
 return this.imgchecked(file)
   },
     imgchecked(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file) // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (!(480<=img.width<=1242&&img.height<=1920)) {
             this.$message.error('480px≤图片宽度≤1242px(图片宽度建议上传750)，0<图片高度≤1920px');
             reject()
            } else {
              resolve(true)
            }
          }
        }
      })
        },
    // 上传logo
      async upLoadShopLogo(file) {
       const formData = new FormData()
       formData.append("file", file.file);
       formData.append("type",1)
      const { data: res } = await this.$http.post(`/api/platform/uploadImages`,formData);
       if (res.code !== 200) return this.$message.error("上传失败");
      this.form.businessLogo= res.body[0].split("?")[0];
     
      this.$message.success("上传成功");
    },
    // 手机号查询是否注册会员
   async  particulars(){
      const { data: res } = await getUserByMobile({mobile:this.businessPhone});
      this.form.userId= res.body.id
     
    },
    // 地图点击取消  初始化地图
    Maptoconfirm(){
      this.form.businessAddressJson.longitude= 122.048705
      this.form.businessAddressJson.latitude=46.07998
        this.form.businessAddressJson.address= "内蒙古自治区兴安盟乌兰浩特市都林街道金泽·时代广场"
      this.form.businessAddressJson.city= "兴安盟" 
      this.form.businessAddressJson.district=  "乌兰浩特市"
      this.form.businessAddressJson.province=  "内蒙古自治区"
      this.innerVisible= false
 
    },
  //打开地图选点
  innerVisibles(){
    this.keyWord = this.bmapSearMsg;
      window.onLoadMap = () => {
        this.ready();
      };
       asyncJS('https://webapi.amap.com/maps?v=1.4.15&key=fe4f84fe1582d0a85a4e64a6e9f3eeb0&callback=onLoadMap');
this.innerVisible=true
  
  },
    addDialogClosed() {},
    handleRemove(file, fileList) {

    },
    handlePreview(file) {
     
    },
    clearActivePath() {
      window.sessionStorage.removeItem("activePath");
      this.$router.push("/index");
    },
    closeBmap () {
        this.$emit('closeBmap');
      },
      comfirmBmap () {
        this.$emit('comfirmBmap', this.longAndLat);
      },
      ready () {
     
        this.map = new window.AMap.Map('container', {
          resizeEnable: true,
           zoom: 20,
          center:[this.form.businessAddressJson.longitude,this.form.businessAddressJson.latitude],
          });
       
          let _this = this;
  // 缩放组件
        window.AMap.plugin('AMap.ToolBar',function(){//异步加载插件
        var toolbar = new AMap.ToolBar();
        _this.map.addControl(toolbar);

    });
          // 为地图注册click事件获取鼠标点击出的经纬度坐标
          this.map.on('click', function (e) {
            let lng = e.lnglat.getLng();
            let lat = e.lnglat.getLat();
              _this.longAndLat = lng + ',' + lat
              _this.Geocoder(lng, lat)
              _this.addMarker(lng, lat);
          });
          // 初始化地图标记点
            this.Geocoder(this.form.businessAddressJson.longitude,this.form.businessAddressJson.latitude)
            this.addMarker(this.form.businessAddressJson.longitude,this.form.businessAddressJson.latitude);
      },
   Geocoder(lng, lat){  
      let _this = this;       // 地图逆编码获取信息
   AMap.plugin('AMap.Geocoder', function() {
  var geocoder = new AMap.Geocoder({
    city: '010'
  })
  var lnglat = [lng, lat]
  geocoder.getAddress(lnglat, function(status, result) {
   
    if (status === 'complete' && result.info === 'OK') {
       if(result.regeocode.addressComponent.city){
   var obj={
     province:result.regeocode.addressComponent.province,
     city:result.regeocode.addressComponent.city,
     district:result.regeocode.addressComponent.district,
     latitude:lat,
     longitude:lng,
     address:result.regeocode.formattedAddress
   }
       }else{
          var obj={
     province:result.regeocode.addressComponent.province,
     city:result.regeocode.addressComponent.province,
     district:result.regeocode.addressComponent.district,
     latitude:lat,
     longitude:lng,
     address:result.regeocode.formattedAddress
   }
       }

     _this.form.businessAddressJson=obj
    
    }
  })
})
   },
      // 高德选择相关地地区
      searchc(e,m){
       
   const _this=this
 window.AMap.plugin('AMap.PlaceSearch', function () {
  
          var autoOptions = {
            city: '全国',
           
            map: _this.map, // 展现结果的地图实例
            pageSize: 10, // 单页显示结果条数
            pageIndex: 1, // 页码
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          };
          var placeSearch = new window.AMap.PlaceSearch(autoOptions);
          placeSearch.search(e, function (status, result) {
           
            // 搜索成功时，result即是对应的匹配数据        
              if (result.poiList.pois.length > 0) {
                 _this.searchOption=[]
                let lng = result.poiList.pois[0].location.lng;
                let lat = result.poiList.pois[0].location.lat;
                _this.longAndLat = lng + ',' + lat;
                 _this.Geocoder(lng, lat)
                _this.addMarker(lng, lat);
               
              } else {
                _this.$message({
                  message: '没有查询到对应的地址',
                  type: 'warning'
                });
                  _this.searchOption=[]
              }
          
          });
        });
      },
      //高德搜索相关地区
      search (e,m) {
        
        let _this = this;
        window.AMap.plugin('AMap.PlaceSearch', function () {
          var autoOptions = {
            city: '全国',
             
            map: _this.map, // 展现结果的地图实例
            pageSize: 10, // 单页显示结果条数
            pageIndex: 1, // 页码
            autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          };
          var placeSearch = new window.AMap.PlaceSearch(autoOptions);
          placeSearch.search(e, function (status, result) {
            // 搜索成功时，result即是对应的匹配数据
            _this.searchOption=result.poiList.pois
          
          });
        });
      },
      // 清除 marker
      clearMarker () {
        if (this.mapMark) {
          this.mapMark.setMap(null);
          this.mapMark = null;
        }},
        // 实例化点标记
      addMarker (lng, lat) {
        // 先清除原来的标记
        // this.clearMarker();
        // 清除所有标记
        this.map.clearMap();
        let _this = this;
        this.mapMark = new window.AMap.Marker({
            position: [lng, lat],
            map: _this.map
        });
       
        this.mapMark.setMap(this.map);
      },
      editText () {
        this.isshowTip = false;
      },
    
    /*
     * 页面加载请求所有的商品数据
     * 默认请求第一页 十条数据
     * */
    async getGoodsList() {
        const { data: res } = await searchBusinessInfoList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("商家列表获取错误");
        this.tableData = res.body.list;
        this.tableData.forEach((element,index) => {
            if(judgeString(element.businessAddressJson)){
            const obj=  JSON.parse(element.businessAddressJson)
              this.tableData[index].businessAddressJson=obj.province+obj.city+obj.district+obj.address
            }
         });
        this.total=res.body.total
     this.loading=false

    },
    
onprovince(data){
  this.queryInfo.page = 1;
  if(data.value=="省"){
this.queryInfo.province= ""
  }else{
    this.queryInfo.province= data.value;
  }


},
    // 选择市
    oncity(data) {
      this.queryInfo.page = 1;
     if(data.code){
 this.queryInfo.city = data.value;
   this.getCategory(data.code.substring(0,4))
     }else{
       this.queryInfo.city = ""
   this.getCategory("")
     }
     
      this.getGoodsList();
    },
    // 选择页数
   handleCurrentChange(data){

this.queryInfo.page=data
   this.loading=false
this.getGoodsList()
    },
// 选择每页展示数量
handleSizeChange(data){
  this.queryInfo.page = 1;
this.queryInfo.size=data
   this.loading=false
this.getGoodsList()
},
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
  watch:{
    
     
      addmerchandise(e){
        if(!e){
            this.initialize()
        }
      },
    // 点击分类弹窗
//     innerVisibler(e){
// if(!e){
//   this.insertBusinessDate={}
// }
//     },
 "form.businessAddressJson"(e){
this.businessAddressJson=[e]
 },
    classifyVisible(){
this.insertBusinessDate={}
    },
 // 入驻点击取消初始化数据
    outerVisible(){
     this.sehe=false
  // 初始化提交数据
  this.form={
        province:"内蒙古自治区",
        logisticsType:1,
        businessAddressJson:{
          address: "内蒙古自治区兴安盟乌兰浩特市都林街道金泽·时代广场",
          city: "兴安盟",
          district: "乌兰浩特市",
         latitude: 46.07998,
       longitude: 122.048705,
         province: "内蒙古自治区"
        },
   businessEndTime: "20:00:00",
   businessStartTime: "08:00:00",
        businessCertificatesJson:{}
      }
      // 初始化上传店内图片数据
      this.addondaryCategoryIdDate=[]
        this.addommodityStatus=[]
        this.businessInPhotoindexList=[]
        this.businessInPhotoList=[]
        this.businessPhone=""
        this.sehe=false
    },
 
  }
 
};
</script>

<style scoped lang="scss">
.mdr5 ::v-deep .el-upload-list{
  display: none;
}
#container{
        padding:0px;
        margin: 0px;
        width: 100%;
        height: 600px;
    }
.mr15 {
  margin-right: 18px;
}

p {
  margin: 0;
  padding: 0;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }
.el-table__fixed::before{
  border: none;
}
    img {
      display: block;

      width: 100px;
    }
  }
}


.v-distpicker ::v-deep select {
  height: 29px !important;

  padding-top: 0.4rem;
  font-size: 12px;
}
.CheckInTimeTitle {
  font-size: 12px;
 
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;

}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
</style>